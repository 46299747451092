import React, { useState, useEffect, useCallback } from 'react';
import {
  Modal,
  ModalBackdrop,
  Text,
  Button,
  Input,
  Checkbox,
  HStack,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  VStack,
  CheckboxIndicator,
  CheckboxLabel,
  CheckboxIcon,
  CheckIcon,
  Icon,
  Icons,
  Pressable,
  ButtonText,
  InputField,
  useAlert,
} from '@tonic/central-specialties-ui-themed';
import { DB } from '@tonic/central-specialties-utils';
import moment from 'moment';
import { TwoWeekGridWithSelection } from '../../general/EntityCardExplorer/DateSelect/TwoWeekGridWithSelection';
import { TimeframeData } from '../../../routes/root/TimeCardsPage/TimeCardsPage';
import { Stepper } from '../Stepper';
import { MessageFlow } from '../MessageFlow';
import { UserModel } from '../../../routes/root/UsersPage/UsersPage';

type ReminderModalProps = {
  isOpen: boolean;
  onClose: () => void;
  data: T[];
  employeeList: UserModel[];
};

type EmployeeRecord = {
  employeeId: string;
  date: string;
  fullName: string;
  hours: number;
  timeCardEntryCount: number;
  status: string;
  hasMessages: boolean;
  jobs: any[];
  supervisors: any[];
  questionResponses: any;
};

type MinimalEmployeeInfo = {
  employeeId: string;
  status: string | null;
  fullName: string;
};

type EmployeesToBeNotified = {
  supervisorsMissingApprovals: string[];
  usersMissingTime: string[];
};

const createEmployeeMap = (
  users: UserModel[],
  records: EmployeeRecord[],
): Map<string, MinimalEmployeeInfo> => {
  const map = new Map<string, MinimalEmployeeInfo>();
  for (const user of users) {
    map.set(user.id, {
      employeeId: user.id,
      status: null,
      fullName: user.fullName,
    });
  }

  for (const record of records) {
    const user = map.get(record.employeeId);
    if (user) {
      user.status = record.status;
    }
  }

  return map;
};

export const ReminderModal = ({
  isOpen,
  onClose,
  data,
  employeeList,
}: ReminderModalProps) => {
  const { alert } = useAlert();
  // ---------------------
  // Step state: 0, 1, 2
  // ---------------------
  const [step, setStep] = useState(0);

  // ---------------------
  // STEP 1: Timeframe
  // ---------------------
  const [selectedRange, setSelectedRange] = useState<TimeframeData>({
    startDate: null,
    endDate: null,
  });

  // ---------------------
  // STEP 2: Employees
  // ---------------------
  const [employees, setEmployees] = useState<Map<string, MinimalEmployeeInfo>>(
    [],
  );
  const [employeesToBeNotified, setEmployeesNeedingNotification] =
    useState<EmployeesToBeNotified>();
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');

  // ---------------------
  // STEP 3: Message
  // ---------------------
  const [messageOption, setMessageOption] = useState('custom');
  const [messageText, setMessageText] = useState('');
  const [quickSelectType, setQuickSelectType] = useState<
    'missing-timecards' | 'missing-approvals' | 'all' | null
  >('all');

  // ---------------------
  // When the modal opens:
  // - Load employees
  // - Reset steps & state
  // ---------------------
  useEffect(() => {
    if (isOpen) {
      setStep(0);
      initDateRange();
      const employeeRecords = createEmployeeMap(employeeList, data);

      setEmployees(employeeRecords);
      setSelectedEmployeeIds([]);
      setSearchText('');
      setMessageText('');
    }
  }, [data, employeeList, isOpen]);

  // ---------------------
  // Initialize date range
  // Example: last 2 full weeks (Sunday to Saturday)
  // But disable days after "today"
  // ---------------------
  const initDateRange = () => {
    const today = moment();
    // Go back 2 weeks, start on Sunday
    const start = today.clone().subtract(2, 'weeks').startOf('week'); // e.g., Sunday
    // End is 1 week from that Sunday’s start, on Saturday
    let end = start.clone().add(1, 'week').endOf('week'); // e.g., Saturday

    // If that end date is beyond today, clamp it
    if (end.isAfter(today)) {
      end = today.clone();
    }

    setSelectedRange({
      startDate: start.format('YYYY-MM-DD'),
      endDate: end.format('YYYY-MM-DD'),
    });
  };

  // ---------------------
  // Navigation
  // ---------------------
  const handleNext = async () => {
    if (step === 0) {
      try {
        await employeesNeedingNotification();
      } catch (error) {
        Alert.alert('Error', 'Failed to fetch employee data.');
      }
    }

    setStep((prev) => prev + 1);
  };
  const handleBack = () => setStep((prev) => prev - 1);

  const employeesNeedingNotification = useCallback(async () => {
    const { startDate, endDate } = selectedRange;
    try {
      await DB.GET('/time-card-entries/employees-needing-notification', {
        params: {
          query: {
            startDate,
            endDate,
          },
        },
      }).then((response) => {
        setEmployeesNeedingNotification(response.data);
      });
    } catch (error) {
      console.error(
        'Unable to grab list of employee(s) needing notification',
        error,
      );
    }
  }, [selectedRange]);

  // ---------------------
  // Final action: Send
  // ---------------------
  const handleSend = useCallback(async () => {
    try {
      await DB.POST('/time-card-entries/send-sms-notification', {
        body: {
          userIds: selectedEmployeeIds,
          message: messageText,
        },
      }).then((response) => {
        const { totalEmployeesNotified } = response.data;
        alert({
          status: 'success',
          message: `Reminders sent to ${totalEmployeesNotified} employee(s)`,
          timeout: 5000,
        });
      });
    } catch (error) {
      alert({
        status: 'error',
        message: `Unable to send reminders to employees`,
        timeout: 10000,
      });
      console.error('Unable to sent out employee time notifications', error);
    }

    onClose();
  }, [alert, messageText, onClose, selectedEmployeeIds]);

  // ---------------------
  // Quick Select
  // ---------------------
  const handleQuickSelect = (
    type: 'missing-timecards' | 'missing-approvals' | 'all',
  ) => {
    const employeeArray = Array.from(employees.values());
    setQuickSelectType(type);
    if (type === 'all') {
      const allEmployeeIds = employeeArray.map((emp) => emp.employeeId);
      setSelectedEmployeeIds(allEmployeeIds);
      setMessageOption('custom');
    } else {
      const employeeSelection: string[] =
        type === 'missing-approvals'
          ? employeesToBeNotified?.supervisorsMissingApprovals ?? []
          : type === 'missing-timecards'
            ? employeesToBeNotified?.usersMissingTime ?? []
            : [];
      const quickSelectIds = employeeArray
        .filter((emp) => employeeSelection.includes(emp.employeeId))
        .map((emp) => emp.employeeId);
      setSelectedEmployeeIds(quickSelectIds);
      setMessageOption('default');
    }
  };

  const handleEmployeeCheck = useCallback(
    (employeeId: string, checked: boolean): void => {
      setMessageOption('custom');
      setSelectedEmployeeIds((prev) => {
        if (checked) {
          return prev.includes(employeeId) ? prev : [...prev, employeeId];
        } else {
          return prev.filter((id) => id !== employeeId);
        }
      });
    },
    [],
  );

  // ---------------------
  // Filter employees by search
  // ---------------------
  const filteredEmployees = Array.from(employees.values()).filter((emp) =>
    emp.fullName.toLowerCase().includes(searchText.toLowerCase()),
  );

  // ---------------------
  // Render Step Content
  // ---------------------
  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <VStack>
            <Text mt="$2" textAlign="center">
              Select date(s) for which to send a reminder text.
            </Text>
            <TwoWeekGridWithSelection onSuccess={setSelectedRange} />
          </VStack>
        );

      case 1:
        return (
          <VStack justifyContent="center" marginHorizontal={'$4'}>
            <Text mt="$2" textAlign="center">
              Quick select employees to send a reminder text, or select
              individual employees from the list below.
            </Text>
            <HStack space="sm" mt="$2" mb="$4" justifyContent="space-evenly">
              <Button onPress={() => handleQuickSelect('missing-timecards')}>
                <ButtonText color="$white">
                  Employees Missing Timecards
                </ButtonText>
              </Button>
              <Button onPress={() => handleQuickSelect('missing-approvals')}>
                <ButtonText color="$white">
                  Employees Missing Approvals
                </ButtonText>
              </Button>
              <Button onPress={() => handleQuickSelect('all')}>
                <ButtonText color="$white">All Employees</ButtonText>
              </Button>
            </HStack>

            {/* A scrollable list of checkboxes */}
            <VStack
              marginLeft={'$2'}
              borderTopWidth={'$1'}
              borderTopColor="$borderLight400"
            >
              <Input
                size="lg"
                sx={{ borderWidth: 1, borderColor: '$gray300' }}
                marginVertical={'$4'}
              >
                <InputField
                  onChangeText={setSearchText}
                  value={searchText}
                  placeholder="Search for an employee..."
                  sx={{ borderWidth: 0 }}
                />
              </Input>
              <VStack
                overflow="scroll"
                maxHeight={'$92'}
                h={'$1/2'}
                marginBottom={'$10'}
                paddingBottom={'$6'}
              >
                {filteredEmployees.map((emp) => (
                  <Checkbox
                    key={`${emp.employeeId}-check`}
                    isChecked={selectedEmployeeIds.includes(emp.employeeId)}
                    defaultIsChecked={false}
                    value={`${emp.employeeId}`}
                    size="lg"
                    aria-label={emp.fullName}
                    marginVertical={'$1'}
                    onChange={(checked) =>
                      handleEmployeeCheck(emp.employeeId, checked)
                    }
                  >
                    <CheckboxIndicator mr="$2">
                      <CheckboxIcon as={CheckIcon} />
                    </CheckboxIndicator>
                    <CheckboxLabel>{emp.fullName}</CheckboxLabel>
                  </Checkbox>
                ))}
              </VStack>
            </VStack>
          </VStack>
        );

      case 2:
        return (
          <MessageFlow
            quickSelectType={quickSelectType}
            messageText={messageText}
            messageOption={messageOption}
            setMessageText={setMessageText}
            setMessageOption={setMessageOption}
          />
        );

      default:
        return null;
    }
  };

  // If the modal isn't open, render nothing
  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBackdrop bg="$gray700" />
      <ModalContent
        borderRadius={0}
        maxHeight="80%"
        w="$96"
        width="60%"
        maxWidth={1000}
        minWidth={720}
      >
        <ModalHeader
          bg="$primary700"
          alignItems="center"
          justifyContent="space-between"
          py="$2"
          pl="$10"
          pr="$6"
        >
          <Text color="$white" size="lg">
            Send Reminder Texts
          </Text>
          <ModalCloseButton w="$8" justifyContent="center" alignItems="center">
            <Icon color="$white" size="xl" as={Icons.X} />
          </ModalCloseButton>
        </ModalHeader>

        <ModalBody w="$full" overflow="hidden">
          <VStack>
            <Stepper currentStep={step} onStepPress={setStep} />
            {renderStep()}
          </VStack>
        </ModalBody>

        <ModalFooter backgroundColor="$white" justifyContent="space-between">
          <HStack space="sm">
            {step > 0 && (
              <Pressable
                backgroundColor="$white"
                borderColor="$primary700"
                borderWidth={'$1'}
                borderRadius="$md"
                paddingHorizontal={'$4'}
                paddingVertical={'$2'}
                onPress={handleBack}
              >
                <Text color="$primary700" textAlign="center" width={'$32'}>
                  Back
                </Text>
              </Pressable>
            )}
            {step < 2 && (
              <Pressable
                backgroundColor="$primary700"
                borderColor="$primary700"
                borderWidth={'$1'}
                borderRadius="$md"
                paddingHorizontal={'$4'}
                paddingVertical={'$2'}
                onPress={handleNext}
              >
                <Text color="$white" textAlign="center" width={'$32'}>
                  Next
                </Text>
              </Pressable>
            )}
            {step === 2 && (
              <Pressable
                backgroundColor="$primary700"
                borderColor="$primary700"
                borderWidth={'$1'}
                borderRadius="$md"
                paddingHorizontal={'$4'}
                paddingVertical={'$2'}
                onPress={handleSend}
              >
                <Text color="$white" textAlign="center" width={'$32'}>
                  Send
                </Text>
              </Pressable>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
