import { useQuery } from '@tanstack/react-query';
import { DB, Schemas } from '@tonic/central-specialties-utils';
import { useCallback, useMemo, useState } from 'react';

interface UseForJobCodeOptionsData {
  laborCodeOptions: Record<string, string>;
  costCodeOptions: Record<string, string>;
  divisionCodeOptions: Record<string, string>;
  extractCostCodeOptions: (id: string) => void;
  isPending: boolean;
  error: unknown;
}

const extractCodeOptions =
  <T extends keyof Schemas['JobCodesByTypeResponse']>(key: T) =>
  (options: Schemas['JobCodesByTypeResponse']) => {
    if (!(options && key in options)) {
      return {};
    }
    return Object.fromEntries(
      options?.[key]?.map((jcObj: Schemas['JobCodeItemResponse']) => {
        return [`${jcObj.code} - ${jcObj.description}`, jcObj.id];
      }),
    );
  };

export const useFormJobCodeOptions = (): UseForJobCodeOptionsData => {
  const [costCodeOptions, setCostCodeOptions] = useState({});
  const {
    data: jobCodesDataGrouped,
    isPending,
    error,
  } = useQuery({
    queryKey: ['jobCodeOptions'],
    queryFn: async () =>
      DB.GET('/job-codes').then(
        (res: { data: Schemas['JobCodesByTypeResponse'] }) => res.data,
      ),
  });
  const extractCostCodeOptions = useCallback(
    (selectedDivisionCode: string) => {
      const selectedDivision = jobCodesDataGrouped?.divisionCodes?.find(
        (div: { id: string }) => div.id === selectedDivisionCode,
      );
      const costCodeOptions = extractCodeOptions('costCodes')(
        selectedDivision || [],
      );
      setCostCodeOptions(costCodeOptions);
    },
    [jobCodesDataGrouped],
  );

  const { laborCodeOptions, divisionCodeOptions } = useMemo(() => {
    return {
      laborCodeOptions: extractCodeOptions('laborCodes')(
        jobCodesDataGrouped || [],
      ),
      divisionCodeOptions: extractCodeOptions('divisionCodes')(
        jobCodesDataGrouped || [],
      ),
    };
  }, [jobCodesDataGrouped]);

  return {
    laborCodeOptions,
    costCodeOptions,
    divisionCodeOptions,
    extractCostCodeOptions,
    isPending,
    error,
  };
};
