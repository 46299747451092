import { TimeCardEntry } from './TimeCard.types.ts';
import {
  Alert,
  AlertText,
  ButtonAsync,
  Center,
  Divider,
  Icons,
  Modal,
  ModalBackdrop,
  ModalContent,
  ModalHeader,
  Text,
  useAlert,
  VStack,
  MessagesForEntity,
  useUser,
} from '@tonic/central-specialties-ui-themed';
import { DB, decimalHoursToTimeString } from '@tonic/central-specialties-utils';
import {
  Button,
  ButtonIcon,
  ButtonText,
  HStack,
  Icon,
  ScrollView,
} from '@gluestack-ui/themed';
import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { TimeCardEntrySummaryCard } from './TimeCardEntrySummaryCard.tsx';
import { TimeCardEntryForm } from '../general/Form/specific/TimeCardEntry/TimeCardEntryForm.tsx';
import { EntityCardColumn } from '../general/EntityCardExplorer/EntityCardColumnsView/EntityCardColumn/EntityCardColumn.tsx';
import { useMutation } from '@tanstack/react-query';
import { TimeCard } from '../../routes/root/TimeCardsPage/TimeCardsPage.tsx';

export interface EmployeeWorkdaySummaryModalProps {
  timeCard: TimeCard;
  date: Date;
  isOpen: boolean;
  onClose: () => void;
  openUnlockModal: (timeCard: TimeCard) => void;
  refetch?: () => void;
}

export const EmployeeWorkdaySummaryModal = ({
  timeCard,
  date,
  isOpen,
  onClose,
  openUnlockModal,
  refetch,
}: EmployeeWorkdaySummaryModalProps) => {
  const { alert } = useAlert();
  const user = useUser();
  const [isPayrollAdmin] = useState(
    user?.permissions?.roles?.includes('PayrollAdmin') ?? false,
  );

  const [selectedTimeCardIndex, setSelectedTimeCardIndex] = useState<
    number | null
  >(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const shouldShowDeleteButton =
    timeCard.status !== 'Approved' ||
    (timeCard.status === 'Approved' && isPayrollAdmin);

  const {
    mutateAsync: submitApproveTimeCard,
    isPending: approvalRequestPending,
  } = useMutation({
    mutationFn: (timeCardEntryIds: string[]) =>
      DB.POST('/time-card-entries/approve', {
        body: {
          timeCardEntryIds,
        },
      }),
  });

  const approveTimeCard = useCallback(async () => {
    try {
      const { error } = await submitApproveTimeCard(timeCard.timeCardEntryIds);
      if (error) {
        throw error;
      }
      alert({
        status: 'success',
        message: 'Time card approved',
        timeout: 5000,
      });
      refetch && refetch();
      onClose();
    } catch (error) {
      console.error(error);
      alert({
        status: 'error',
        message: 'There was an error approving this time card entry',
        timeout: 10000,
      });
    }
  }, [
    submitApproveTimeCard,
    alert,
    timeCard.timeCardEntryIds,
    onClose,
    refetch,
  ]);

  const {
    mutateAsync: deleteSelectedTimeCard,
    isPending: deleteRequestPending,
  } = useMutation({
    mutationFn: (timeCardEntryId: string) =>
      DB.DELETE(`/time-card-entries/${timeCardEntryId}`),
  });

  const handleDeletePress = () => {
    setShowDeleteAlert((prev) => !prev);
  };

  const deleteTimeCard = useCallback(async () => {
    if (selectedTimeCardIndex === null || selectedTimeCardIndex < 0) return;

    const timeCardEntryId =
      timeCard?.timeCardEntries[selectedTimeCardIndex]?.id ?? null;

    if (!timeCardEntryId) return;
    try {
      const { error } = await deleteSelectedTimeCard(timeCardEntryId);
      if (error) {
        throw error;
      }
      alert({
        status: 'success',
        message: 'Time card deleted',
        timeout: 5000,
      });
      refetch && refetch();
      onClose();
    } catch (error) {
      console.error(error);
      alert({
        status: 'error',
        message: 'There was an error deleting this time card entry',
        timeout: 10000,
      });
    }
  }, [
    deleteSelectedTimeCard,
    alert,
    timeCard?.timeCardEntries,
    selectedTimeCardIndex,
    onClose,
    refetch,
  ]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBackdrop />
      <ModalContent
        borderRadius={0}
        h="$full"
        bg="$gray100"
        maxWidth="90%"
        maxHeight="80%"
      >
        <ModalHeader bg="$primary700" alignItems="center" py="$2">
          <HStack
            h="$full"
            alignItems="center"
            flex={1}
            justifyContent="space-between"
          >
            <HStack alignItems="center" space="md">
              <Text size="xl" color="$white" bold>
                {timeCard?.employee?.fullName}
              </Text>
              <Text size="xl" color="$white">
                -
              </Text>
              <Text size="lg" color="$white">
                {moment(date).format('MMMM DD, YYYY')}
              </Text>
            </HStack>
            <HStack alignItems="center" gap={'$1'}>
              <Text color="$white">
                Today's Date:{' '}
                {moment().utc(true).tz('America/Chicago').format('M/D/YY')}
              </Text>
              {selectedTimeCardIndex !== null ? (
                <Text color="$white">
                  Last updated:{' '}
                  {moment(
                    timeCard.timeCardEntries[selectedTimeCardIndex].updatedAt,
                  )
                    .utc(true)
                    .tz('America/Chicago')
                    .format('h:mma M/D/YY')}
                </Text>
              ) : (
                // If no individual card is selected, show the most recent updatedAt value of all entries
                <Text color="$white">
                  Last updated:{' '}
                  {timeCard.timeCardEntries.length === 0
                    ? '  -'
                    : moment(
                        timeCard.timeCardEntries.reduce((acc, entry) => {
                          const date = new Date(entry.updatedAt);
                          return date > acc ? date : acc;
                        }, new Date(0)),
                      )
                        .utc(true)
                        .tz('America/Chicago')
                        .format('h:mma M/D/YY')}
                </Text>
              )}
            </HStack>
          </HStack>
          <Button
            w="$12"
            minWidth="auto"
            ml="$6"
            onPress={() => openUnlockModal(timeCard)}
          >
            <Icon color="$white" as={Icons.Lock} />
          </Button>
          <Button ml="$3" w="$12" minWidth="auto" onPress={onClose}>
            <Icon color="$white" size="xl" as={Icons.X} />
          </Button>
        </ModalHeader>
        <HStack flex={1} pt="$8" px="$6" gap="$8">
          <VStack flex={1} h="$full" alignItems="center">
            <Text size="xl" color="$textNormal">
              Total hours: {decimalHoursToTimeString(timeCard.totalTime, false)}
            </Text>
            <EntityCardColumn
              items={timeCard.timeCardEntries}
              CardComponent={({
                data: timeCardEntry,
                index,
              }: {
                data: TimeCardEntry;
                index: number;
              }) => (
                <TimeCardEntrySummaryCard
                  timeCardEntry={timeCardEntry}
                  onPress={() => {
                    setSelectedTimeCardIndex(index);
                  }}
                  selected={selectedTimeCardIndex === index}
                />
              )}
            />
            {shouldShowDeleteButton && (
              <Center p="$4" w="$full">
                <ButtonAsync
                  size="lg"
                  onPress={handleDeletePress}
                  pending={deleteRequestPending}
                  isDisabled={
                    selectedTimeCardIndex !== null && selectedTimeCardIndex >= 0
                      ? !timeCard.timeCardEntries[selectedTimeCardIndex]
                      : true
                  }
                >
                  <ButtonIcon as={Icons.Delete} />
                  <ButtonText>Delete</ButtonText>
                </ButtonAsync>
              </Center>
            )}
            {timeCard.status !== 'Approved' && (
              <Center p="$4" w="$full">
                <ButtonAsync
                  size="lg"
                  onPress={approveTimeCard}
                  pending={approvalRequestPending}
                >
                  <ButtonIcon as={Icons.Check} />
                  <ButtonText>Approve</ButtonText>
                </ButtonAsync>
              </Center>
            )}
          </VStack>
          <Divider orientation="vertical" subtle />
          <VStack flex={2}>
            {selectedTimeCardIndex === null ? (
              <Center flex={1}>
                <Text size="lg" color="$textNormal">
                  Select time entry to view
                </Text>
              </Center>
            ) : (
              <TimeCardEntryDetails
                key={selectedTimeCardIndex}
                timeCard={timeCard.timeCardEntries[selectedTimeCardIndex]}
                date={date}
                refetch={refetch}
              />
            )}
          </VStack>
        </HStack>
      </ModalContent>
      {showDeleteAlert && (
        <Alert
          action={'warning'}
          position={'absolute'}
          variant="outline"
          flex={1}
          flexDirection="row"
          sx={{
            p: '$4',
            gap: '$2',
            top: 0,
            w: '$full',
            zIndex: 99999,
          }}
        >
          <AlertText>
            {'Are you sure you want to delete the selected time card entry?'}
          </AlertText>

          <ButtonAsync
            size="lg"
            onPress={handleDeletePress}
            pending={deleteRequestPending}
          >
            <ButtonIcon as={Icons.XCircle} />
            <ButtonText>Cancel</ButtonText>
          </ButtonAsync>

          <ButtonAsync
            size="lg"
            onPress={deleteTimeCard}
            pending={deleteRequestPending}
          >
            <ButtonIcon as={Icons.Delete} />
            <ButtonText>Delete</ButtonText>
          </ButtonAsync>
        </Alert>
      )}
    </Modal>
  );
};

export interface TimeCardEntryDetailsProps {
  timeCard: TimeCardEntry;
  date: Date;
  refetch?: () => void;
}

export const TimeCardEntryDetails = ({
  timeCard,
  date,
  refetch,
}: TimeCardEntryDetailsProps) => {
  return (
    <HStack flex={1} gap="$8">
      <ScrollView flex={1} p="$0.5" pb="$10">
        <TimeCardEntryForm
          timeCardEntryId={timeCard?.id}
          targetDate={date}
          onSuccess={refetch}
        />
      </ScrollView>
      <Divider orientation="vertical" subtle />
      <VStack flex={1} pb="$10">
        <MessagesForEntity
          entityKind="time-card-entries"
          entityId={timeCard.id}
        />
      </VStack>
    </HStack>
  );
};
