// DateRangePicker.tsx
import React, { useState, useRef, useCallback } from 'react';
import { Alert, Dimensions } from 'react-native';
import {
  CalendarList,
  CalendarListProps,
  DateData,
} from 'react-native-calendars';
import moment from 'moment';
import {
  HStack,
  Icon,
  Icons,
  Modal,
  ModalBackdrop,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Text,
  VStack,
  ModalCloseButton,
  ModalBody,
  Pressable,
  Button,
  ButtonText,
  View,
  Spinner,
} from '@tonic/central-specialties-ui-themed';
import { TimeframeData } from '../../routes/root/TimeCardsPage/TimeCardsPage';
import { CustomDay } from './EntityCardExplorer/DateSelect/CustomDay';

// Props for the DateRangePicker component
interface DateRangePickerProps {
  visible: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: (range: TimeframeData) => void;
}

type MarkedDates = {
  [date: string]: {
    startingDay?: boolean;
    endingDay?: boolean;
    color?: string;
    textColor?: string;
  };
};

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  visible,
  onClose,
  onConfirm,
  loading = false,
}) => {
  const calendarRef = useRef<CalendarListProps>(null);
  const [selectedRange, setSelectedRange] = useState<TimeframeData>({
    startDate: null,
    endDate: null,
  });
  // 0 = Current Week, 1 = Current Month, 2 = Last 30 Days, 3 = Custom
  const [activeIndex, setActiveIndex] = useState(3);

  const getMarkedDates = (): MarkedDates => {
    const marked: MarkedDates = {};

    if (selectedRange.startDate) {
      // Mark the start date
      marked[selectedRange.startDate] = {
        startingDay: true,
        color: '#FFDADA',
        textColor: 'white',
      };
    }
    if (selectedRange.startDate && selectedRange.endDate) {
      const start = moment(selectedRange.startDate);
      const end = moment(selectedRange.endDate);
      const diffDays = end.diff(start, 'days');

      // Loop through the range and mark each day
      for (let i = 0; i <= diffDays; i++) {
        const date = start.clone().add(i, 'days').format('YYYY-MM-DD');
        if (i === 0) {
          marked[date] = {
            startingDay: true,
            color: '#FFDADA',
            textColor: 'white',
          };
        } else if (i === diffDays) {
          marked[date] = {
            endingDay: true,
            color: '#FFDADA',
            textColor: 'white',
          };
        } else {
          marked[date] = { color: '#FFDADA', textColor: 'white' };
        }
      }
    }
    return marked;
  };

  // When a day is pressed on the calendar
  const handleDayPress = (day: DateData) => {
    const { dateString } = day;
    const today = moment().format('YYYY-MM-DD');
    setActiveIndex(3);

    // Disallow future dates
    if (moment(dateString).isAfter(today)) {
      Alert.alert('Invalid Date', 'Cannot select a future date.');
      return;
    }

    // If no start date (or if a range was already completed) then start a new range
    if (
      !selectedRange.startDate ||
      (selectedRange.startDate && selectedRange.endDate)
    ) {
      setSelectedRange({ startDate: dateString, endDate: null });
    } else {
      // We have a start date but no end date. Determine the order.
      let start = moment(selectedRange.startDate);
      let end = moment(dateString);
      if (end.isBefore(start)) {
        // If the new date is before the current start date, swap them.
        [start, end] = [end, start];
      }
      // Check that the range does not exceed 31 days
      const rangeDays = end.diff(start, 'days') + 1;
      if (rangeDays > 31) {
        Alert.alert(
          'Invalid Range',
          'Please select a range of 31 days or less.',
        );
        return;
      }
      setSelectedRange({
        startDate: start.format('YYYY-MM-DD'),
        endDate: end.format('YYYY-MM-DD'),
      });
    }
  };

  // Preset: Current Week (starting on Monday) – from Monday to today
  const selectCurrentWeek = () => {
    setActiveIndex(0);
    const start = moment().startOf('week');
    const end = moment(); // current day
    setSelectedRange({
      startDate: start.format('YYYY-MM-DD'),
      endDate: end.format('YYYY-MM-DD'),
    });
    if (calendarRef.current) {
      calendarRef.current.scrollToDay(start.format('YYYY-MM-DD'), 0, true);
    }
  };

  // Preset: Current Month to Date (from the 1st of the month to today)
  const selectCurrentMonthToDate = () => {
    setActiveIndex(1);
    const start = moment().startOf('month');
    const end = moment();
    setSelectedRange({
      startDate: start.format('YYYY-MM-DD'),
      endDate: end.format('YYYY-MM-DD'),
    });
    if (calendarRef.current) {
      calendarRef.current.scrollToDay(start.format('YYYY-MM-DD'), 0, true);
    }
  };

  const selectLast30Days = () => {
    setActiveIndex(2);
    const end = moment();
    const start = moment().subtract(29, 'days');
    setSelectedRange({
      startDate: start.format('YYYY-MM-DD'),
      endDate: end.format('YYYY-MM-DD'),
    });
    if (calendarRef.current) {
      calendarRef.current.scrollToDay(start.format('YYYY-MM-DD'), 0, true);
    }
  };

  const currentPayPeriod = useCallback(() => {
    if (!selectedRange?.startDate || !selectedRange?.endDate) return null;
    const { startDate, endDate } = selectedRange;
    const startOfPeriod = moment(startDate);
    const endOfPeriod = moment(endDate);

    return `${startOfPeriod.format('MM/DD/YYYY')} through ${endOfPeriod.format('MM/DD/YYYY')}`;
  }, [selectedRange]);

  const windowWidth = Dimensions.get('window').width;
  const calendarWidth = Math.min(windowWidth * 0.46, 800);
  const isDisabled = useCallback(() => {
    return (
      loading ||
      !selectedRange ||
      !selectedRange.startDate ||
      !selectedRange.endDate
    );
  }, [loading, selectedRange]);

  return (
    <Modal isOpen={visible} onClose={onClose}>
      <ModalBackdrop bg="$gray700" />
      <ModalContent
        borderRadius={0}
        maxHeight="80%"
        w="$96"
        width="60%"
        maxWidth={1000}
        minWidth={480}
      >
        <ModalHeader
          bg="$primary700"
          alignItems="center"
          justifyContent="space-between"
          py="$2"
          pl="$10"
          pr="$6"
        >
          <Text color="$white" size="lg">
            Export Time Cards
          </Text>
          <ModalCloseButton w="$8" justifyContent="center" alignItems="center">
            <Icon color="$white" size="xl" as={Icons.X} />
          </ModalCloseButton>
        </ModalHeader>
        <ModalBody w="$full">
          <HStack justifyContent="stretch">
            <Text
              alignContent="center"
              fontSize={'$lg'}
              marginLeft={'$4'}
              marginVertical={'$2.5'}
              textAlign="center"
            >
              Selected Date:
            </Text>
            <Text
              backgroundColor="$white"
              padding={
                selectedRange?.endDate && selectedRange?.startDate ? '$2' : 0
              }
              borderRadius={'$md'}
              fontSize={'$lg'}
              textAlign="center"
              marginVertical={'$2.5'}
            >
              {currentPayPeriod()}
            </Text>
          </HStack>
          <HStack>
            <VStack
              id="preset-button-container"
              backgroundColor="$secondary100"
            >
              <Button
                size="md"
                px="$4"
                variant={activeIndex === 0 ? 'solid' : 'outline'}
                marginVertical={'$2'}
                onPress={selectCurrentWeek}
              >
                <ButtonText
                  color={activeIndex === 0 ? '$white' : '$primary700'}
                  fontSize={'$lg'}
                >
                  Current Week
                </ButtonText>
              </Button>
              <Button
                size="md"
                px="$4"
                variant={activeIndex === 1 ? 'solid' : 'outline'}
                marginVertical={'$2'}
                onPress={selectCurrentMonthToDate}
              >
                <ButtonText
                  color={activeIndex === 1 ? '$white' : '$primary700'}
                  fontSize={'$lg'}
                >
                  Current Month
                </ButtonText>
              </Button>
              <Button
                size="md"
                px="$4"
                variant={activeIndex === 2 ? 'solid' : 'outline'}
                marginVertical={'$2'}
                onPress={selectLast30Days}
              >
                <ButtonText
                  color={activeIndex === 2 ? '$white' : '$primary700'}
                  fontSize={'$lg'}
                >
                  Last 30 Days
                </ButtonText>
              </Button>
              <Button
                size="md"
                px="$4"
                variant={activeIndex === 3 ? 'solid' : 'outline'}
                marginVertical={'$2'}
                onPress={() => {
                  setSelectedRange({ startDate: null, endDate: null });
                  setActiveIndex(3);
                }}
              >
                <ButtonText
                  color={activeIndex === 3 ? '$white' : '$primary700'}
                  fontSize={'$lg'}
                >
                  Custom
                </ButtonText>
              </Button>
            </VStack>
            <VStack>
              <HStack
                justifyContent="space-around"
                paddingVertical={'$2'}
                maxWidth={calendarWidth}
              >
                {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map(
                  (day) => (
                    <View alignItems="center" key={day}>
                      <Text fontWeight="bold" color="$textDark700">
                        {day}
                      </Text>
                    </View>
                  ),
                )}
              </HStack>
              <CalendarList
                key={'calendar-list-date-range-picker'}
                ref={calendarRef}
                hideDayNames
                onDayPress={handleDayPress}
                markedDates={getMarkedDates()}
                markingType="period"
                pastScrollRange={14}
                futureScrollRange={0}
                scrollEnabled={true}
                horizontal={false}
                showScrollIndicator={true}
                maxDate={moment().format('YYYY-MM-DD')}
                minDate={moment().subtract(14, 'months').format('YYYY-MM-DD')}
                current={moment().format('YYYY-MM-DD')}
                monthFormat="MMM yyyy"
                theme={{
                  calendarBackground: '#F8F8F8',
                  textSectionTitleColor: '#000',
                  dayTextColor: '#000',
                  todayTextColor: '#000',
                  selectedDayBackgroundColor: '#FFDADA',
                  selectedDayTextColor: '#9D0101',
                  textDayFontWeight: 'bold',
                }}
                style={{ height: 400, maxWidth: 840 }}
                calendarWidth={calendarWidth}
                dayComponent={(props) => (
                  <CustomDay
                    {...props}
                    onDayPress={(day) => handleDayPress(day)}
                  />
                )}
              />
            </VStack>
          </HStack>
        </ModalBody>
        <ModalFooter backgroundColor="$white" justifyContent="space-between">
          <Pressable
            backgroundColor="$white"
            borderColor="$primary700"
            borderWidth={'$1'}
            borderRadius="$md"
            paddingHorizontal={'$4'}
            paddingVertical={'$2'}
            onPress={onClose}
          >
            <Text color="$primary700" textAlign="center" width={'$32'}>
              Cancel
            </Text>
          </Pressable>
          <Pressable
            backgroundColor={isDisabled() ? '$secondary300' : '$primary700'}
            borderColor="$primary700"
            borderWidth={'$1'}
            borderRadius="$md"
            paddingHorizontal={'$4'}
            paddingVertical={'$2'}
            onPress={() => onConfirm(selectedRange)}
            disabled={isDisabled()}
          >
            {loading && (
              <Spinner
                color="$white"
                justifyContent="center"
                alignContent="center"
                width={'$32'}
              />
            )}
            {!loading && (
              <Text color="$white" textAlign="center" width={'$32'}>
                Export
              </Text>
            )}
          </Pressable>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DateRangePicker;
