import React, { useState } from 'react';
import { Input, InputField, InputIcon, InputSlot } from '@gluestack-ui/themed';
import { Icons } from '@tonic/central-specialties-ui-themed';
import { TouchableOpacity } from 'react-native';

interface SearchBarProps extends Partial<React.ComponentProps<typeof Input>> {
  onChangeText: (text: string) => void;
  value?: string;
  inputFieldProps?: Partial<React.ComponentProps<typeof InputField>>;
  inputSlotProps?: Partial<React.ComponentProps<typeof InputSlot>>;
  inputIconProps?: Partial<React.ComponentProps<typeof InputIcon>>;
}

export const SearchBar = ({
  onChangeText,
  value,
  inputFieldProps,
  inputSlotProps,
  inputIconProps,
  ...inputProps
}: SearchBarProps) => {
  const [text, setText] = useState(value || '');

  const handleClear = () => {
    setText('');
    onChangeText('');
  };

  const handleTextChange = (newValue: string) => {
    setText(newValue);
    onChangeText(newValue);
  };
  return (
    <Input
      size="sm"
      sx={{ borderWidth: 1, borderColor: '$gray300' }}
      {...inputProps}
    >
      <InputField
        onChangeText={handleTextChange}
        value={text}
        placeholder="Search..."
        sx={{ borderWidth: 0 }}
        {...inputFieldProps}
      />
      <InputSlot bg="$white" pr="$1.5" flexDirection="row" {...inputSlotProps}>
        {!!text && text?.length > 0 && (
          <TouchableOpacity onPress={handleClear} style={{ marginRight: 8 }}>
            <InputIcon as={Icons.X} size="lg" color="$textNormal" />
          </TouchableOpacity>
        )}
        <TouchableOpacity>
          <InputIcon
            as={Icons.Search}
            size="lg"
            color="$textNormal"
            {...inputIconProps}
          />
        </TouchableOpacity>
      </InputSlot>
    </Input>
  );
};
