import React, { useCallback } from 'react';
import { CalendarList, DateData } from 'react-native-calendars';
import moment, { Moment } from 'moment';
import {
  ModalBackdrop,
  Modal,
  View,
  Text,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Icon,
  Icons,
  VStack,
  ModalBody,
  HStack,
} from '@tonic/central-specialties-ui-themed';
import { CustomDay } from './EntityCardExplorer/DateSelect/CustomDay';

interface SimpleDatePickerProps {
  visible: boolean;
  onClose: () => void;
  onDateSelect: (
    start: Moment,
    end: Moment,
    datePickerSelectedDate: Moment,
    jumpToWeek: boolean,
  ) => void;
}

const SimpleDatePicker: React.FC<SimpleDatePickerProps> = ({
  visible,
  onClose,
  onDateSelect,
}) => {
  const handleDayPress = useCallback(
    (day: DateData) => {
      const { dateString } = day;
      const momentDatestring = moment(dateString);
      const startOfSelectedWeek = momentDatestring.clone().startOf('week');
      const endofSelectedWeek = momentDatestring.clone().endOf('week');

      onDateSelect(
        startOfSelectedWeek,
        endofSelectedWeek,
        momentDatestring,
        true,
      );
      onClose();
    },
    [onClose, onDateSelect],
  );

  const minDate = '2024-04-01';

  const today = moment();

  const april2024 = moment(minDate);

  // Calculate the number of months between today and April 2024.
  // This will be the maximum number of months the user can scroll back.
  const pastScrollRange = today.isAfter(april2024)
    ? today.diff(april2024, 'months')
    : 0;

  return (
    <Modal isOpen={visible} onClose={onClose}>
      <ModalBackdrop bg="$gray700" />
      <ModalContent
        borderRadius={0}
        maxHeight="80%"
        w="$96"
        width="60%"
        maxWidth={840}
        minWidth={480}
      >
        <ModalHeader
          bg="$primary700"
          alignItems="center"
          justifyContent="space-between"
          py="$2"
          pl="$10"
          pr="$6"
        >
          <Text color="$white" size="lg">
            Select Pay Period
          </Text>
          <ModalCloseButton w="$8" justifyContent="center" alignItems="center">
            <Icon color="$white" size="xl" as={Icons.X} />
          </ModalCloseButton>
        </ModalHeader>
        <ModalBody w="$full">
          <VStack>
            <HStack
              justifyContent="space-around"
              paddingVertical={'$2'}
              maxWidth={800}
            >
              {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map((day) => (
                <View alignItems="center" key={day}>
                  <Text fontWeight="bold" color="$textDark700">
                    {day}
                  </Text>
                </View>
              ))}
            </HStack>
            <CalendarList
              // Set the date boundaries.
              current={today.format('YYYY-MM-DD')}
              pastScrollRange={pastScrollRange}
              futureScrollRange={1}
              hideDayNames
              scrollEnabled
              showScrollIndicator={true}
              monthFormat="MMM yyyy"
              // When a user taps a day, send the date back and close the modal.
              onDayPress={handleDayPress}
              theme={{
                calendarBackground: '#F8F8F8',
                textSectionTitleColor: '#000',
                dayTextColor: '#000',
                todayTextColor: '#000',
                selectedDayBackgroundColor: '#FFDADA',
                selectedDayTextColor: '#9D0101',
                textDayFontWeight: 'bold',
              }}
              style={{ height: 400, maxWidth: 840 }}
              calendarWidth={800}
              dayComponent={(props) => (
                <CustomDay
                  {...props}
                  onDayPress={handleDayPress}
                  allowFutureSelection={true}
                />
              )}
            />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SimpleDatePicker;
