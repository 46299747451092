import { useQuery } from '@tanstack/react-query';
import { DB, Schemas } from '@tonic/central-specialties-utils';
import { useMemo } from 'react';

interface UseFormJobOptionsData {
  allJobs: Schemas['JobResponse'][] | null;
  jobOptions: Record<string, string>;
  isPending: boolean;
  error: Error | null;
}

export const useFormJobOptions = (): UseFormJobOptionsData => {
  const {
    data: allJobs,
    isPending,
    error,
  } = useQuery({
    queryKey: ['allJobs'],
    queryFn: async () =>
      DB.GET('/jobs').then(
        (res: { data: Schemas['JobResponse'][] }) => res.data,
      ),
  });

  const jobOptions = useMemo(() => {
    if (!allJobs) {
      return {};
    }
    return Object.fromEntries(
      allJobs
        .filter((j: Schemas['JobCodeModel']) => j?.id && j?.number)
        .map((jcObj: Schemas['JobCodeModel']) => {
          return [`${jcObj.number}: ${jcObj.description}`, jcObj.id];
        }),
    );
  }, [allJobs]);

  return {
    allJobs,
    jobOptions,
    isPending,
    error,
  };
};
