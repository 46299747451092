import React, { useCallback, useMemo, useState } from 'react';
import { Schemas } from '@tonic/central-specialties-utils';
import {
  Box,
  Center,
  Icons,
  Modal,
  ModalBackdrop,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from '@tonic/central-specialties-ui-themed';
import {
  Button,
  ButtonIcon,
  FlatList,
  HStack,
  Icon,
  Text,
} from '@gluestack-ui/themed';
import { RepairRequestCard } from '../../../../components/general/cards/RepairRequestCard.tsx';

interface ArchivedRepairRequestsModalProps {
  isOpen: boolean;
  onClose: () => void;
  repairRequests: Schemas['RepairRequestResponse'][];
  onSelectTicket: (ticket: Schemas['RepairRequestResponse']) => void;
}

export const ArchivedRepairRequestsModal = ({
  isOpen,
  onClose,
  repairRequests,
  onSelectTicket,
}: ArchivedRepairRequestsModalProps) => {
  const [page, setPage] = useState(0);
  const itemsPerPage = 12;
  // sort repair requests by updatedAt, with newest requests at the top
  const sortedRepairRequests = useMemo(() => {
    const sorted = [...repairRequests];
    sorted.sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateB.getTime() - dateA.getTime();
    });
    return sorted;
  }, [repairRequests]);

  const totalPages = useMemo(
    () => Math.ceil(sortedRepairRequests.length / itemsPerPage),
    [sortedRepairRequests.length, itemsPerPage],
  );

  const currentRepairItems = useMemo(() => {
    const startIndex = page * itemsPerPage;
    return sortedRepairRequests.slice(startIndex, startIndex + itemsPerPage);
  }, [sortedRepairRequests, page, itemsPerPage]);

  const handleSelectTicket = useCallback(
    (item) => {
      onSelectTicket({ ...item, status: 'Archived' });
    },
    [onSelectTicket],
  );

  // Navigation handlers
  const goToPrevPage = useCallback(() => {
    setPage((prev) => Math.max(0, prev - 1));
  }, []);

  const goToNextPage = useCallback(() => {
    setPage((prev) => Math.min(totalPages - 1, prev + 1));
  }, [totalPages]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalBackdrop />
      <ModalContent
        borderRadius={0}
        maxHeight="90%"
        maxWidth="90%"
        minWidth={500}
      >
        <ModalHeader
          bg="$primary700"
          alignItems="center"
          justifyContent="space-between"
          py="$2"
          pl="$10"
          pr="$6"
        >
          <Text color="$white" size="lg">
            Archived Tickets
          </Text>
          <HStack space="lg" alignItems="center" gap="$6">
            <Center>
              <ModalCloseButton
                w="$8"
                justifyContent="center"
                alignItems="center"
              >
                <Icon color="$white" size="xl" as={Icons.X} />
              </ModalCloseButton>
            </Center>
          </HStack>
        </ModalHeader>

        <ModalBody
          w="$full"
          p="$4"
          my="$2"
          flex={1}
          justifyContent="space-between"
          minHeight="$40"
        >
          <FlatList
            data={currentRepairItems}
            keyExtractor={(item) => item.id}
            numColumns={3}
            renderItem={({ item }) => (
              <Box w="$1/3" py="$1.5" px="$2.5">
                <RepairRequestCard
                  repairRequest={item}
                  onPress={() => handleSelectTicket(item)}
                  cardProps={{ flex: 1 }}
                />
              </Box>
            )}
            ListEmptyComponent={() => (
              <Center w="$full" h="$full">
                <Text size="lg">No archived repair requests to show</Text>
              </Center>
            )}
            initialNumToRender={12}
          />

          {/* Pagination Controls */}
          <HStack space="md" justifyContent="center" alignItems="center" p="$4">
            <Button
              variant="outline"
              isDisabled={page === 0}
              onPress={goToPrevPage}
            >
              <ButtonIcon as={Icons.ChevronLeft} />
            </Button>

            <Text>
              Page {page + 1} of {totalPages}
              {totalPages > 0 ? ` (${sortedRepairRequests.length} items)` : ''}
            </Text>

            <Button
              variant="outline"
              isDisabled={page >= totalPages - 1}
              onPress={goToNextPage}
            >
              <ButtonIcon as={Icons.ChevronRight} />
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
