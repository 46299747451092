import {
  ChevronDownIcon,
  Select,
  SelectBackdrop,
  SelectContent,
  SelectDragIndicator,
  SelectDragIndicatorWrapper,
  SelectIcon,
  SelectInput,
  SelectItem,
  SelectPortal,
  SelectTrigger,
  scaleSizeVariant,
} from '@tonic/central-specialties-ui-themed';
import { FormFieldData } from '../FormField.types.ts';
import React, { useMemo } from 'react';
import { Text } from '@gluestack-ui/themed';

export interface SelectFormFieldData {
  options: Record<string, string | boolean | number>;
  // If true, the select will be styled all big and red like (see the 'Mechanic' field of the repair request form in the admin panel designs for reference)
  isSuperSelect?: boolean;
  placeholder?: string;
}

export const SelectFormField = ({
  value,
  onChange,
  options: inputOptions,
  readonly,
  disabled,
  placeholder,
  isSuperSelect = false,
  size = 'md',
}: FormFieldData<'Select'>) => {
  const options = useMemo(() => {
    if (Array.isArray(inputOptions)) {
      return Object.fromEntries(inputOptions.map((val) => [val, val]));
    }
    return inputOptions;
  }, [inputOptions]);

  const valueLabel = useMemo(() => {
    const entry = Object.entries(options).find(([_, val]) => val === value);
    return entry ? entry[0] : '';
  }, [options, value]);

  if (readonly) {
    return (
      <Text size={size} pl="$0.5">
        {valueLabel || 'Not specified'}
      </Text>
    );
  }

  // @ts-expect-error - in this case we're okay with bigger sizes
  size = isSuperSelect ? scaleSizeVariant(size, 2) : size;

  return (
    <Select
      size={size}
      selectedValue={value ? value : null}
      initialLabel={valueLabel || undefined}
      isDisabled={disabled}
      onValueChange={onChange}
      {...(isSuperSelect ? superSelectStyles.select : {})}
    >
      <SelectTrigger
        size={size}
        variant="outline"
        {...(isSuperSelect ? superSelectStyles.trigger : {})}
      >
        <SelectInput
          size={size}
          placeholder={placeholder}
          {...(isSuperSelect ? superSelectStyles.input : {})}
        />
        <SelectIcon
          as={ChevronDownIcon}
          mr="$3"
          {...(isSuperSelect ? superSelectStyles.icon : {})}
        />
      </SelectTrigger>
      <SelectPortal {...(isSuperSelect ? superSelectStyles.portal : {})}>
        <SelectBackdrop />
        <SelectContent>
          <SelectDragIndicatorWrapper>
            <SelectDragIndicator />
          </SelectDragIndicatorWrapper>
          <SelectItem value="" label="" disabled />
          {Object.entries(options).map(([label, value]) => (
            <SelectItem
              key={label + 'selectOption'}
              label={label}
              value={value}
              isSelected={label === valueLabel}
            />
          ))}
        </SelectContent>
      </SelectPortal>
    </Select>
  );
};

const superSelectStyles = {
  select: {
    flexGrow: 0,
    width: 'auto',
  },
  trigger: {
    width: 'auto',
    bg: '$primary700',
    justifyContent: 'center',
    px: '$4',
  },
  input: {
    padding: 0,
    placeholderTextColor: '$white',
    textAlign: 'center',
    color: '$white',
  },
  icon: {
    color: '$white',
  },
  portal: {},
};
