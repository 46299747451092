import React, { useCallback, useEffect, useMemo } from 'react';
import { FormFieldData } from '../../FormField.types.ts';
import { Divider, Icons, VStack } from '@tonic/central-specialties-ui-themed';
import { Schemas } from '@tonic/central-specialties-utils';
import { ButtonIcon, HStack, Pressable } from '@gluestack-ui/themed';
import { FormFieldControl } from '../../FormFieldControl.tsx';

export interface PartsFormFieldData {}

interface ImageObject {
  id: string;
  url: string;
  thumbnailUrl: string;
  caption?: string | null;
}

const deduplicateImages = (images: ImageObject[]): ImageObject[] => {
  const uniqueImages: { [id: string]: ImageObject } = {};

  images.forEach((image) => {
    if (!uniqueImages[image.id]) {
      uniqueImages[image.id] = image;
    } else {
      // If a duplicate is found, prefer the version with a caption.
      if (!uniqueImages[image.id].caption && image.caption) {
        uniqueImages[image.id] = image;
      }
    }
  });

  return Object.values(uniqueImages);
};

export const PartsFormField = ({
  value,
  onChange, // Type confusion between field data type and props type. Fix if time allows
  readonly,
}: FormFieldData<'PartRequests'>) => {
  const addNewPart = useCallback(() => {
    if (onChange) {
      onChange([...value, { partNumber: '', images: [] }]);
    }
  }, [value, onChange]);

  useEffect(() => {
    if (value.length === 0) {
      addNewPart();
    }
  }, [value]);

  return (
    <VStack gap="$6">
      {!!value &&
        value.map((part, index) => (
          <React.Fragment key={`partField:${index}:${part.images.length}`}>
            {index === 0 && <Divider subtle size="md" />}
            <PartField
              part={part}
              index={index}
              readonly={readonly}
              addNewPart={addNewPart}
              removePart={
                value.length > 1
                  ? () => {
                      const newValue = [...value];
                      newValue.splice(index, 1);
                      onChange(newValue);
                    }
                  : undefined
              }
              onChange={(part) => {
                const newValue = [...value];
                newValue[index] = part;
                onChange(newValue);
              }}
            />
            <Divider subtle size="md" />
          </React.Fragment>
        ))}
    </VStack>
  );
};

interface PartFieldProps {
  part?: Schemas['PartRequestResponse'];
  index?: number;
  readonly?: boolean;
  removePart?: () => void;
  addNewPart?: () => void;
  onChange: (part: Schemas['PartRequestResponse']) => void;
  isDisabled: boolean;
}

const PartField = ({
  part,
  index,
  readonly = false,
  removePart,
  addNewPart,
  onChange,
}: PartFieldProps) => {
  const uniqueImages = useMemo(() => {
    return deduplicateImages(part.images);
  }, [part.images]);

  return (
    <HStack w="$full" gap="$2">
      {!readonly && (
        <VStack>
          <HStack gap="$3">
            {!!removePart && (
              <Pressable onPress={removePart}>
                <ButtonIcon
                  size="md"
                  as={Icons.MinusCircle}
                  color="$textNormal"
                />
              </Pressable>
            )}
            {!!addNewPart && (
              <Pressable onPress={addNewPart}>
                <ButtonIcon
                  size="md"
                  as={Icons.PlusCircle}
                  color="$textNormal"
                />
              </Pressable>
            )}
          </HStack>
        </VStack>
      )}
      <VStack gap="$3" flex={1}>
        <FormFieldControl
          type="Text"
          label={'Part number' + (index !== undefined ? ` ${index + 1}` : '')}
          readonly={readonly}
          placeholder="Enter part number"
          value={part?.partNumber}
          onChange={(nStr) => {
            // TODO: do we need to enforce numbers only?
            onChange({ ...part, partNumber: nStr });
          }}
        />
        <FormFieldControl
          type="Images"
          label="Part photos"
          readonly={readonly}
          value={uniqueImages}
          onChange={(images) => onChange({ ...part, images })}
          placeholder="Option to add photo of part"
          addButtonSize="md"
          displayCaption={true}
        />
      </VStack>
    </HStack>
  );
};
