import React, { useState } from 'react';
import { Alert } from 'react-native';
import { FlatList } from '@gluestack-ui/themed';
import moment from 'moment';
import { CustomDay } from './CustomDay';
import { DateData } from 'react-native-calendars';

type SelectedRange = {
  startDate: string | null;
  endDate: string | null;
};

type TwoWeekGridWithSelectionProps = {
  allowFutureSelection?: boolean;
  onSuccess?: React.Dispatch<React.SetStateAction<any>>;
};

export const TwoWeekGridWithSelection: React.FC<
  TwoWeekGridWithSelectionProps
> = ({ allowFutureSelection = false, onSuccess }) => {
  // State to hold the currently selected range.
  const [selectedRange, setSelectedRange] = useState<SelectedRange>({
    startDate: null,
    endDate: null,
  });

  // Create an array of 14 DateData objects.
  const days: DateData[] = Array.from({ length: 14 }, (_, i) => {
    // Compute the start of the 14-day grid.
    // For example, if today is Wednesday Feb 26, then:
    // moment().subtract(1, 'week').startOf('week') yields Sunday Feb 16.
    const gridStart = moment().subtract(1, 'week').startOf('week');
    const date = gridStart.clone().add(i, 'days');
    return {
      dateString: date.format('YYYY-MM-DD'),
      day: date.date(),
      month: date.month() + 1, // moment months are 0-indexed
      year: date.year(),
      timestamp: date.valueOf(),
    };
  });

  // Compute a "marking" for each day based on the selectedRange.
  const getMarkingForDate = (dateString: string) => {
    if (!selectedRange.startDate) return undefined;

    const current = moment(dateString, 'YYYY-MM-DD');
    const start = moment(selectedRange.startDate, 'YYYY-MM-DD');

    // If only a start date is selected, mark only that day.
    if (!selectedRange.endDate) {
      if (current.isSame(start, 'day')) {
        return {
          color: '#FFDADA',
          textColor: 'white',
          startingDay: true,
          endingDay: true,
        };
      }
      return undefined;
    }

    // If a full range is selected:
    const end = moment(selectedRange.endDate, 'YYYY-MM-DD');

    if (current.isSame(start, 'day')) {
      return { color: '#FFDADA', textColor: 'white', startingDay: true };
    } else if (current.isSame(end, 'day')) {
      return { color: '#FFDADA', textColor: 'white', endingDay: true };
    } else if (current.isBetween(start, end, 'day', '[]')) {
      return { color: '#FFDADA', textColor: 'white' };
    }
    return undefined;
  };

  // Combined day press handler that updates the selected range.
  const handleDayPress = (day: DateData) => {
    const { dateString } = day;
    const today = moment().format('YYYY-MM-DD');

    // Disallow future dates.
    if (moment(dateString).isAfter(today)) {
      Alert.alert('Invalid Date', 'Cannot select a future date.');
      return;
    }

    // If no start date is set, or a complete range exists, start a new range.
    if (
      !selectedRange.startDate ||
      (selectedRange.startDate && selectedRange.endDate)
    ) {
      setSelectedRange({ startDate: dateString, endDate: null });
      onSuccess &&
        onSuccess({
          startDate: moment(dateString, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          endDate: moment(dateString, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        });
    } else {
      // A start date exists but no end date.
      let start = moment(selectedRange.startDate, 'YYYY-MM-DD');
      let end = moment(dateString, 'YYYY-MM-DD');

      // If the new date is before the start, swap them.
      if (end.isBefore(start)) {
        [start, end] = [end, start];
      }

      setSelectedRange({
        startDate: start.format('YYYY-MM-DD'),
        endDate: end.format('YYYY-MM-DD'),
      });
      // TODO Better name
      onSuccess &&
        onSuccess({
          startDate: start.format('YYYY-MM-DD'),
          endDate: end.format('YYYY-MM-DD'),
        });
    }
  };

  return (
    <FlatList
      data={days}
      keyExtractor={(item) => item.dateString}
      numColumns={7} // 7 columns to represent a week
      renderItem={(item) => (
        <CustomDay
          date={item?.item}
          marking={getMarkingForDate(item?.item.dateString)}
          onDayPress={handleDayPress}
          allowFutureSelection={allowFutureSelection}
          disallowCurrentDay={true}
        />
      )}
      contentContainerStyle={{
        alignItems: 'center',
      }}
    />
  );
};
