import React from 'react';
import { Pressable, Box, HStack, VStack, Text } from '@gluestack-ui/themed';

type StepperProps = {
  steps?: string[];
  currentStep: number;
  onStepPress: (stepIndex: number) => void;
};

/**
 * A 3-step horizontal stepper with:
 *  - Labels above circles
 *  - A single horizontal line behind the circles
 *  - Filled circles (#9D0101) for completed or active steps
 *  - Bold + colored label for completed/active steps
 */
export const Stepper: React.FC<StepperProps> = ({
  steps = ['Timeframe', 'Employee(s)', 'Messages'],
  currentStep,
  onStepPress,
}) => {
  return (
    <HStack
      id="stepper-component"
      position="relative"
      width="100%"
      alignItems="flex-start"
      justifyContent="space-between"
      paddingVertical="$4"
    >
      <Box
        position="absolute"
        left={0}
        right={0}
        height="$0.5"
        backgroundColor="#9D0101"
        top="46px"
        zIndex={-1}
      />

      {steps.map((label, index) => {
        const isActiveOrPast = index <= currentStep;
        const circleColor = isActiveOrPast ? '#9D0101' : '$white';
        const labelColor = '$black';
        const labelWeight = isActiveOrPast ? '$bold' : '$normal';

        return (
          <Pressable
            key={label}
            id={`stepper-${label}-button`}
            onPress={() => {
              if (isActiveOrPast) {
                onStepPress(index);
              }
            }}
            disabled={!isActiveOrPast}
          >
            <VStack alignItems="center">
              <Text
                marginBottom="$1"
                fontWeight={labelWeight}
                color={labelColor}
              >
                {label}
              </Text>

              <Box
                height="$6"
                width="$6"
                borderRadius="$full"
                alignItems="center"
                justifyContent="center"
                backgroundColor={circleColor}
                borderWidth={1}
                borderColor="#9D0101"
              />
            </VStack>
          </Pressable>
        );
      })}
    </HStack>
  );
};
