import ReactNativeCalendarStrip from 'react-native-calendar-strip';
import { HStack, Icon, useToken } from '@gluestack-ui/themed';
import {
  Icons,
  Pressable,
  Text,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import {
  CustomCalendarStripDay,
  IDayComponentProps,
} from './CustomCalendarStripDay/CustomCalendarStripDay.tsx';
import { Moment } from 'moment/moment.js';
import moment from 'moment';
import { useCallback, useState } from 'react';
import SimpleDatePicker from '../../SimpleDatePicker.tsx';

interface DateSelectStripProps {
  date: Moment;
  onDateSelected: (date: Moment) => void;
  dateCounts: Record<string, number>;
  updateDateRange: (start: Moment, end: Moment) => void;
}

export const DateSelectStrip = ({
  date,
  onDateSelected,
  dateCounts = {},
  updateDateRange,
}: DateSelectStripProps) => {
  const [dateRangePickerVisible, setDateRangePickerVisible] =
    useState<boolean>(false);

  const primaryRed700 = useToken('colors', 'primary700');
  const calendarKey = Object.values(dateCounts).join('');

  const currentPayPeriod = useCallback(() => {
    const startOfWeek = date.clone().day(0);
    const endOfWeek = date.clone().day(6);

    // Format the result as "Mon DD - Mon DD, YYYY"
    return `Pay Period: ${startOfWeek.format('MMM D')} - ${endOfWeek.format('MMM D, YYYY')}`;
  }, [date]);

  return (
    <VStack w="$full">
      <ReactNativeCalendarStrip
        key={calendarKey}
        onDateSelected={onDateSelected}
        onWeekChanged={updateDateRange}
        selectedDate={date}
        headerText={''}
        style={[{ height: 165 }]} // prevent shift caused by vanishing when key changes
        // innerStyle={[]}
        calendarHeaderStyle={{
          display: 'none',
          /* fontFamily: 'Arial',
          fontSize: 20,
          fontWeight: '700',
          backgroundColor: primaryRed700,
          width: '100%',
          // height: 60,
          padding: 18,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          // position: 'relative',
          borderRadius: 5, */
        }}
        calendarAnimation={{ type: 'parallel', duration: 200 }}
        calendarHeaderPosition={'below'}
        // calendarHeaderFormat={'dddd, MMMM DD'}
        shouldAllowFontScaling={false}
        leftSelector={<Icon mr="$5" as={Icons.ChevronLeft} />}
        rightSelector={<Icon ml="$5" as={Icons.ChevronRight} />}
        dayContainerStyle={{
          // prevent shifting on select
          borderTopColor: 'transparent',
          borderTopWidth: 2,
        }}
        highlightDateContainerStyle={{
          borderTopColor: primaryRed700,
          borderColor: primaryRed700,
          borderWidth: 2,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          borderBottomWidth: 0,
        }}
        dayComponent={(props: IDayComponentProps) => {
          const stripDay = props.date.toISOString();
          const numUncompleted =
            dateCounts[moment(stripDay).format('YYYY-MM-DD')];
          const loading = numUncompleted === undefined;
          const { key, ...restProps } = props;
          return (
            <CustomCalendarStripDay
              key={key ?? stripDay}
              numUncompleted={numUncompleted || 0}
              loading={loading}
              {...restProps}
            />
          );
        }}
        // scrollable
        // scrollerPaging
        // maxDayComponentSize={110}
        // minDayComponentSize={110}
        // dayComponentHeight={110}
        startingDate={date.clone().startOf('week')}
        useIsoWeekday={false}
      ></ReactNativeCalendarStrip>
      <HStack
        w="$full"
        bgColor={primaryRed700}
        alignItems="center"
        justifyContent="space-between"
        height={'$16'}
      >
        <Pressable
          onPress={() => setDateRangePickerVisible(true)}
          borderColor="$white"
          borderWidth={'$1'}
          borderRadius={'$sm'}
          padding="$3"
          flexDirection="row"
          marginLeft={'$1.5'}
        >
          <Icon
            size="md"
            as={Icons.Calendar}
            color="$white"
            marginRight={'$2'}
          />
          <Text color="$white">{currentPayPeriod()}</Text>
        </Pressable>
        <Text marginHorizontal={'auto'} color="$white" size="lg">
          {date.format('dddd, MMMM DD YYYY')}
        </Text>
      </HStack>
      {dateRangePickerVisible && (
        <SimpleDatePicker
          visible={dateRangePickerVisible}
          onClose={() => setDateRangePickerVisible(false)}
          onDateSelect={updateDateRange}
        />
      )}
    </VStack>
  );
};
