import {
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  HStack,
} from '@gluestack-ui/themed';
import {
  FormControlError,
  FormControlErrorText,
  FormControlHelper,
  FormControlHelperText,
} from '@tonic/central-specialties-ui-themed';
import {
  FormFieldData,
  FormFieldType,
  isFormFieldDeclaration,
} from './FormField.types.ts';
import { CheckboxFormField } from './specific';
import { FormField } from './FormField.tsx';

export const FormFieldControl = <T extends FormFieldType>(
  fieldProps: FormFieldData<T>,
) => {
  const {
    label,
    required = false,
    softRequired = false,
    invalid = false,
    readonly,
    helperText,
    errorText,
    hidden,
    labelRightElement: LabelRightElement,
    size = 'md',
    formControlProps,
  } = fieldProps;

  if (hidden) {
    return null;
  }

  if (fieldProps.type === 'Checkbox') {
    return <CheckboxFormField {...(fieldProps as FormFieldData<'Checkbox'>)} />;
  }

  return (
    <FormControl
      size={size}
      isRequired={(required || softRequired) && !readonly}
      isInvalid={invalid}
      gap="$1"
      px="$0.5"
      py="$1"
      w="$full"
      {...formControlProps}
    >
      <HStack
        w="$full"
        justifyContent="space-between"
        mb={LabelRightElement ? '$2' : 0}
      >
        {!!label && (
          <FormControlLabel>
            <FormControlLabelText fontWeight="$bold">
              {label}
            </FormControlLabelText>
          </FormControlLabel>
        )}
        {LabelRightElement &&
          (isFormFieldDeclaration(LabelRightElement) ? (
            <FormField {...LabelRightElement} />
          ) : (
            LabelRightElement
          ))}
      </HStack>

      <FormField {...(fieldProps as FormFieldData<typeof fieldProps.type>)} />

      {!!helperText && (
        <FormControlHelper>
          <FormControlHelperText size={size}>
            {helperText}
          </FormControlHelperText>
        </FormControlHelper>
      )}

      {!!errorText && (
        <FormControlError>
          <FormControlErrorText size={size}>{errorText}</FormControlErrorText>
        </FormControlError>
      )}
    </FormControl>
  );
};
