import {
  ArrayDataUtility,
  DataStructureBase,
  DeepDataValue,
  DeepPath,
  getDeepValue,
} from '@tonic/central-specialties-utils';
import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonIcon,
  ButtonText,
  Center,
  ScrollView,
  Text,
  VStack,
} from '@gluestack-ui/themed';
import { Icons } from '@tonic/central-specialties-ui-themed';
import { SidebarModal } from '../modals/SidebarModal.tsx';
import { Form, FormFieldsObject } from '../Form';
import moment from 'moment';
import { useSearchFilter } from '../../../hooks/useSearchFilter.ts';
import { SearchBar } from '../SearchBar.tsx';

///---------------------------------------------------------------------------------------------------------------------
// SUPPORTING TYPES & STANDARD CONFIGURATIONS
///---------------------------------------------------------------------------------------------------------------------

///--------------------------------------------------------------------
// Common Option Formatting Configurations
///--------------------------------------------------------------------
type OptionFormattingFunction = (
  options: any[],
) => string[] | Record<string, string>;

const standardFormatters = {
  DEFAULT: (options: any[]) => options,
  noNull: (options: any[]) => options.filter((opt: any) => !!opt),
  // get only the date part of the timestamp
  date: (timestamps: string[]) => [
    ...new Set(
      timestamps.map((ts) =>
        moment(ts).utc(true).tz('America/Chicago').format('M/D/YY'),
      ),
    ),
  ],
} as const satisfies Record<string, OptionFormattingFunction>;

type StandardFormatterKey = keyof typeof standardFormatters;

///--------------------------------------------------------------------
// Common Option Sorters
///--------------------------------------------------------------------
type OptionSortingFunction = (optionA: any, optionB: any) => number;

const standardSorters = {
  // Default is just alphabetical
  DEFAULT: (optionA: any, optionB: any) => {
    if (typeof optionA === 'string' && typeof optionB === 'string') {
      return optionA.localeCompare(optionB);
    }
    return optionA - optionB;
  },
  numerical: (n1: number, n2: number) => {
    if (!(typeof n1 === 'number' && typeof n2 === 'number')) {
      console.warn(
        "You shouldn't use numerical filter options sort on fields that contain non-numerical values. Recieved ",
        {
          n1,
          n2,
        },
      );
      return 0;
    }
    return n1 - n2;
  },
  date: (dateStringA: string, dateStringB: string) => {
    return moment(dateStringA).valueOf() - moment(dateStringB).valueOf();
  },
  nosort: () => 0,
} as const satisfies Record<string, OptionSortingFunction>;
type StandardSorterKey = keyof typeof standardSorters;

///--------------------------------------------------------------------
// Common Value->Option Matching Configurations
///--------------------------------------------------------------------
type FilterMatchingFunction = (value: any, filterValues: any[]) => boolean;

const standardMatchers = {
  DEFAULT: (value: any[], filterValues: any[]): boolean =>
    filterValues.includes(value),
  date: (timestamp: string, formattedDates: string[]) => {
    return formattedDates.includes(
      moment(timestamp).utc(true).tz('America/Chicago').format('M/D/YY'),
    );
  },
} as const satisfies Record<string, FilterMatchingFunction>;
type StandardMatcherKey = keyof typeof standardMatchers;

///--------------------------------------------------------------------
// Data that defines what values to filter by and how to filter them
///--------------------------------------------------------------------
interface FilterData<T extends DataStructureBase> {
  path: Record<string, DeepPath<T>>;
  formatOptions?: OptionFormattingFunction | StandardFormatterKey;
  matcher?: FilterMatchingFunction | StandardMatcherKey;
  sortOptions?: OptionSortingFunction | StandardSorterKey;
}

export type FiltersData<T extends DataStructureBase> = Record<
  string,
  FilterData<T>
>;

///---------------------------------------------------------------------------------------------------------------------
// COMPONENT
///---------------------------------------------------------------------------------------------------------------------
export interface FilterButtonProps<T extends DataStructureBase>
  extends React.ComponentProps<typeof Button> {
  /**
   * Needed to generate possible values
   */
  data: T[];
  /**
   * Setter for the filtered data. Used for the "output" of this filter component
   */
  setFilteredData: React.Dispatch<React.SetStateAction<T[]>>;
  /**
   * Paths of T that you want to be able to filter by, keyed by their label
   */
  filtersData: FiltersData<T>;
}

export const FilterButton = <T extends DataStructureBase>({
  data,
  filtersData,
  setFilteredData,
  ...buttonProps
}: FilterButtonProps<T>) => {
  const [filterSelectOpen, setFiltersSelectOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState<
    Record<string, DeepDataValue<T, any>[]>
  >({});

  const [filterFieldData, setFilterFieldData] = useState(
    Object.fromEntries(Object.keys(filtersData).map((key) => [key, []])),
  );
  const [formFields, setFormFields] = useState<FormFieldsObject>([]);
  const [searchValue, setSearchValue] = useState('');
  const searchFilteredData = useSearchFilter(data, searchValue, [
    'fullName',
    'hasMessages',
    'priority',
    'status',
    ['assignedTo', 'fullName'],
    ['createdBy', 'fullName'],
    ['equipment', 'description'],
    ['equipment', 'number'],
    ['job', 'number'],
    ['supervisors', 'name'],
    ['trucks', 'companyName'],
  ]);

  // on mount (or if data or filterData changes), generate the form fields based on available values in data matching the paths in filterData
  useEffect(() => {
    // Collect all options within data to display for each path specified in filtersData
    const filterFieldOptions = Object.fromEntries(
      Object.entries(filtersData).map(([key, filterData]) => {
        const { path } = filterData;
        let { formatOptions, sortOptions = 'DEFAULT' } = filterData;
        // Gather all unique values within data for the given path
        const options = [
          ...new Set([
            ...new ArrayDataUtility(searchFilteredData).iterate({
              path,
            }),
          ]),
        ];
        // If a specific formatter is passed for options, use it
        if (formatOptions) {
          // If it's a key specifying a standard formatter, use it (or DEFAULT if it's invalid)
          if (typeof formatOptions === 'string') {
            if (!(formatOptions in standardFormatters)) {
              console.error(
                'WARNING: Invalid formatter key in filtersData passed to FilterButton, defaulting to DEFAULT. key passed: ',
                formatOptions,
                ' formatter must be a formatter function or a key of standardFormatters: ',
                Object.keys(standardFormatters).join(', '),
              );
              formatOptions = 'DEFAULT';
            }
            formatOptions = standardFormatters[formatOptions];
          }

          if (sortOptions) {
            if (typeof sortOptions === 'string') {
              if (!(sortOptions in standardSorters)) {
                console.error(
                  'WARNING: Invalid sorter key in filtersData passed to FilterButton, defaulting to DEFAULT. key passed: ',
                  sortOptions,
                  ' sorter must be a sorter function or a key of standardSorters: ',
                  Object.keys(standardSorters).join(', '),
                );
                sortOptions = 'DEFAULT';
              }
              sortOptions = standardSorters[sortOptions];
            }
          }
          const formattedOptions = formatOptions(options);
          formattedOptions.sort(sortOptions);
          return [key, formattedOptions];
        }
        // Otherwise use the raw values
        return [key, options];
      }),
    );

    const filterFormFields = Object.fromEntries(
      Object.entries(filterFieldOptions)
        // filter out any fields with no options
        .filter(([_, v]) => v.length > 0)
        // generate a field object for each based on the type of data options contains
        .map(([key, value]) => {
          const createField = () => {
            switch (typeof value?.[0]) {
              case 'string':
                return {
                  type: 'CheckboxGroup',
                  dataFieldKey: key,
                  label: key,
                  options: value,
                  size: 'lg',
                };
              case 'boolean':
                return {
                  type: 'Radio',
                  dataFieldKey: key,
                  label: key,
                  options: { Yes: true, No: false },
                  size: 'lg',
                };
              default:
                return {
                  type: 'Unknown Type',
                };
            }
          };
          return [key, createField()];
        }),
    );

    // Generate form fields based on the options
    setFormFields(filterFormFields);
  }, [searchFilteredData, filtersData]);

  // Filter the data when the active filters change
  useEffect(() => {
    const filtered = searchFilteredData.filter((item: T) => {
      return Object.entries(activeFilters).every(
        ([pathLabel, filterValueOptions]) => {
          const { path } = filtersData[pathLabel];
          // matcher defaults to equality within the array of options
          let { matcher = standardMatchers['DEFAULT'] } =
            filtersData[pathLabel];

          // if matcher is a key of standardMatchers, use the standard matcher it references
          if (typeof matcher === 'string') {
            if (matcher in standardMatchers) {
              matcher = standardMatchers[matcher];
            } else {
              console.error(
                'WARNING: Invalid matcher key in filtersData passed to FilterButton, defaulting to DEFAULT. key passed: ',
                matcher,
                ' matcher must be a matcher function or a key of standardMatchers: ',
                Object.keys(standardMatchers).join(', '),
              );
              matcher = standardMatchers.DEFAULT;
            }
          }
          const value = getDeepValue(item, path);

          // If the current filter item is not wrapped in an array, wrap it
          if (!Array.isArray(filterValueOptions)) {
            filterValueOptions = [filterValueOptions];
          }
          // If the current filter item specifies no values, no need to filter the item
          if (!filterValueOptions.length) {
            return true;
          }
          // If the value returned from getDeepValue is an array (item contains an array between the start and end of the path), return true if any of the values match
          if (Array.isArray(value)) {
            return value.some((v) => matcher(v, filterValueOptions));
          }
          // Otherwise, if value is a raw value, just return whether it's one of the specified valeus
          return matcher(value, filterValueOptions);
        },
      );
    });
    setFilteredData(filtered);
  }, [filtersData, activeFilters, searchFilteredData, setFilteredData]);

  if ('onPress' in buttonProps) {
    console.warn(
      'FilterButton has its own onPress functionality, overriding it may not be what you want to do. Check the component props',
    );
  }

  const onPressApplyFilters = (selectedFilters: typeof filterFieldData) => {
    setActiveFilters(selectedFilters);
    setFiltersSelectOpen(false);
  };

  const checkCanApply = (formData) => {
    return Object.values(formData).some(
      (v) => !Array.isArray(v) || v.length > 0,
    );
  };

  const clearState = () => {
    setActiveFilters({});
    setFilterFieldData(
      Object.fromEntries(Object.keys(filtersData).map((key) => [key, []])),
    );
  };

  return (
    <>
      <Button
        sx={{
          bg: Object.keys(activeFilters).length > 0 ? '$primary200' : undefined,
          ':hover': {
            bg: '$primary100',
          },
        }}
        variant="ghost"
        size="lg"
        onPress={() => setFiltersSelectOpen(!filterSelectOpen)}
        {...buttonProps}
      >
        <ButtonIcon color="$primary700" as={Icons.Filter} />
        <ButtonText>Filter</ButtonText>
      </Button>

      <SidebarModal
        isOpen={filterSelectOpen}
        onClose={() => setFiltersSelectOpen(false)}
      >
        <VStack
          alignItems="center"
          flex={1}
          justifyContent="space-between"
          pt={0}
          p="$6"
          pr="$2"
        >
          <Center w="$full" marginBottom={'$2'}>
            <SearchBar onChangeText={setSearchValue} />
          </Center>
          {searchFilteredData && searchFilteredData?.length === 0 && (
            <Center w="$full" h="$full">
              <Text size="lg">
                {searchValue
                  ? 'No results match search'
                  : 'No disabled users to show'}
              </Text>
            </Center>
          )}
          <Form
            size="lg"
            gap="$12"
            fields={formFields}
            checkCanSubmit={checkCanApply}
            onSubmit={onPressApplyFilters}
            formData={filterFieldData}
            setFormData={setFilterFieldData}
            Container={({ children }) => (
              <ScrollView mb="$4" w="$full" flex={1}>
                <VStack flex={1} w="$full">
                  {children}
                </VStack>
              </ScrollView>
            )}
            SubmitButton={({ submit, disabled }) => (
              <Center>
                <VStack space="sm">
                  <Button
                    onPress={submit}
                    isDisabled={disabled}
                    size="lg"
                    px="$8"
                  >
                    {/* TODO: add count like designs if time allows */}
                    <ButtonText>
                      Apply{' '}
                      {
                        Object.values(filterFieldData).filter(
                          (a) => !Array.isArray(a) || a.length,
                        ).length
                      }{' '}
                      Filters
                    </ButtonText>
                  </Button>
                  <Button
                    onPress={async () => {
                      await clearState();
                    }}
                    size="sm"
                    variant="link"
                    isDisabled={Object.keys(activeFilters).length === 0}
                    bg="transparent"
                  >
                    <ButtonText>Clear all filters</ButtonText>
                  </Button>
                </VStack>
              </Center>
            )}
          />
        </VStack>
      </SidebarModal>
    </>
  );
};
