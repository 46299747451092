import { Pressable, Text, View } from '@tonic/central-specialties-ui-themed';
import moment from 'moment';
import { DateData } from 'react-native-calendars';
import { MarkedDates } from 'react-native-calendars/src/types';

type CustomDayProps = {
  date: DateData;
  marking?: MarkedDates;
  state?: 'selected' | 'disabled' | 'today' | '';
  onDayPress?: (day: DateData) => void;
  allowFutureSelection?: boolean;
  disallowCurrentDay?: boolean;
};

export const CustomDay = ({
  date,
  marking,
  onDayPress,
  allowFutureSelection,
  disallowCurrentDay,
}: CustomDayProps) => {
  const { dateString } = date;
  const isSelected = marking?.color === '#FFDADA';
  const maxSelectableDate = allowFutureSelection
    ? moment().endOf('week')
    : moment();
  const isAfterMaxDate = moment(dateString).isAfter(maxSelectableDate, 'day');

  // If disallowCurrentDay is true, disable the exact current day as well.
  const isCurrentDay = moment(dateString).isSame(moment(), 'day');
  const isDisabled = isAfterMaxDate || (disallowCurrentDay && isCurrentDay);

  return (
    <Pressable
      key={`${date?.dateString}-press`}
      disabled={isDisabled}
      onPress={() => {
        onDayPress && onDayPress(date);
      }}
      android_ripple={{ color: '#ccc', borderless: false }}
      style={({ pressed }) => [
        // add a little pressed-state style on iOS
        { opacity: pressed ? 0.8 : 1.0 },
      ]}
    >
      <View
        backgroundColor={
          isSelected ? '$primary100' : isDisabled ? '#F8F8F8' : '$secondary300'
        }
        borderRadius={'$md'}
        margin={'$0.5'}
        paddingVertical={'$2'}
        paddingHorizontal={'$3'}
        alignItems="center"
        justifyContent="center"
        height={'$12'}
        width={'$20'}
      >
        <Text
          color={
            isSelected ? '$primary700' : isDisabled ? '$textLight400' : '$black'
          }
          fontWeight={isSelected ? '$bold' : '$normal'}
        >
          {date.day}
        </Text>
      </View>
    </Pressable>
  );
};
