import React from 'react';
import { FormFieldData } from '../FormField.types.ts';
import {
  Icons,
  Image,
  SizeVariant,
  Text,
  VStack,
} from '@tonic/central-specialties-ui-themed';
import {
  Button,
  ButtonText,
  HStack,
  Icon,
  Pressable,
} from '@gluestack-ui/themed';
import { openImageFiles } from '../../../../../utils/openFileOrFiles.ts';
import { Schemas } from '@tonic/central-specialties-utils';
import { TextFormField } from './TextFormField.tsx';

export interface ImagesFormFieldData {
  placeholder?: string;
  addButtonSize?: SizeVariant;
}

interface PartsImageData extends Partial<Schemas['ImageModel']> {
  isNew: boolean;
  id: string;
  url: string;
  thumbnailUrl: string;
  caption?: string;
}

// TODO: remove image button
export const ImagesFormField = ({
  value,
  onChange,
  placeholder,
  readonly,
  addButtonSize = 'lg',
  displayCaption = false,
}: FormFieldData<'Images'>) => {
  return (
    <VStack w="$full" gap="$2">
      <HStack w="$full" flexWrap="wrap" gap="$4">
        {value && value.length ? (
          value.map((imageData: PartsImageData, i: number) => (
            <HStack w="$full">
              <Pressable
                key={imageData.id}
                position="relative"
                onPress={() => {
                  // TODO: If form brought to mobile, this will need to be changed
                  window.open(imageData.url, '_blank');
                }}
              >
                <Image
                  source={{ uri: imageData.url }}
                  alt={'image:' + i}
                  size="md"
                />

                {!readonly && (
                  <Pressable
                    borderRadius="$full"
                    bg="$black"
                    position="absolute"
                    top={-4}
                    right={-4}
                    onPress={() => {
                      const newValue = [...value];
                      newValue.splice(i, 1);
                      onChange(newValue);
                    }}
                  >
                    <Icon color="$white" as={Icons.XCircle} />
                  </Pressable>
                )}
              </Pressable>
              {displayCaption && (
                <VStack ml={'$2'} w="$3/4">
                  <TextFormField
                    value={imageData.caption || ''}
                    onChange={(text) => {
                      const updatedCaption = text.slice(0, 100);
                      const updatedImage = {
                        ...imageData,
                        caption: updatedCaption,
                      };
                      const updatedImages = [...value];
                      updatedImages[i] = updatedImage;
                      onChange(updatedImages);
                    }}
                    multiline={false}
                    label={'Description'}
                    placeholder={'Add an optional description'}
                  />
                  {!readonly && (
                    <Text fontSize={'$sm'} color="$gray500" ml={'$1'}>
                      {(imageData.caption || '').length}/100 characters
                    </Text>
                  )}
                </VStack>
              )}
            </HStack>
          ))
        ) : (
          <Text>{(!readonly && placeholder) || 'No images'}</Text>
        )}
      </HStack>
      <HStack>
        {!readonly && (
          <Button
            bg="transparent"
            minWidth="auto"
            sx={{
              ':disabled': {
                opacity: 0.5,
              },
            }}
            // Add button is disabled if there are already empties
            isDisabled={!value.every((v) => v)}
            onPress={async () => {
              // TODO: If form is brought to mobile, this will need to be changed
              const imageFiles: File[] = await openImageFiles(true);
              if (imageFiles) {
                const imageData: PartsImageData[] = imageFiles.map(
                  (imgFile) => {
                    return {
                      isNew: true, // add this so we know which images are new and need to be uploaded upon update
                      id: imgFile.name,
                      url: URL.createObjectURL(imgFile),
                      thumbnailUrl: URL.createObjectURL(imgFile),
                    };
                  },
                );
                onChange([...value, ...imageData]);
              }
            }}
            px="$0"
          >
            <Icon
              size={addButtonSize}
              as={Icons.PlusCircle}
              color="$textNormal"
            />
            <ButtonText size={addButtonSize} color="$textNormal">
              Add Photo
            </ButtonText>
          </Button>
        )}
      </HStack>
    </VStack>
  );
};
