import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonIcon,
  ButtonText,
  Box,
  Checkbox,
  CheckboxIndicator,
  CheckboxIcon,
  DB,
  HStack,
  Heading,
  Icon,
  IconButton,
  Icons,
  Modal,
  ModalBackdrop,
  ModalContent,
  ModalHeader,
  Pressable,
  ScrollView,
  Spinner,
  Text,
  useUser,
  View,
  VStack,
  useAlert,
} from '@tonic/central-specialties-ui-themed';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ChevronRight } from 'react-native-feather';

export interface NotificationDrawerProps
  extends React.ComponentProps<typeof ModalContent> {
  isOpen: boolean;
  onClose: () => void;
  defaultFilterType?: string;
}

const NotificationSourceKeys = [
  'truckingRequestId',
  'timeCardEntryId',
  'repairRequestId',
  'partsTicketId',
];

const iconMap = {
  Repair: Icons.Tool,
  'Time Card': Icons.Clock,
  Parts: Icons.Hexagon,
  Trucking: Icons.Truck,
  Critical: Icons.AlertTriangle,
};

const RoutePageMap = {
  truckingRequestId: 'Truck',
  timeCardEntryId: 'Time',
  repairRequestId: 'Repair',
  partsTicketId: 'Parts',
};
const NotificationTypeMap = {
  All: 'All',
  Time: 'TimeEntry',
  Parts: 'PartsRequest',
  Repair: 'RepairRequest',
  Truck: 'TruckingRequest',
};

interface NotificationProp {
  id: string;
  type: string;
  sender: string;
  jobNumber: string;
  isRead: boolean;
  sentAt: string | Date;
  truckingRequestId: string | null;
  timeCardEntryId: string | null;
  repairRequestId: string | null;
  partsTicketId: string | null;
}

interface NotificationCardDataProp {
  id: string;
  subject: string;
  subheader: string;
  isRead: boolean;
  icon: string;
  messageId: string;
  type: string;
}

interface NotificationsResponse {
  totalCount: number;
  unreadCount: number;
  notifications: NotificationProp[];
}

interface NotificationCardProp {
  iconName: IIconComponentType<any>;
  subject: string;
  subheader: string;
  readMessage: boolean;
  id: string;
  onPress: () => void;
  archiveSelectionActive: boolean;
  isSelected: boolean;
  onSelectToggle: () => void;
}

export const NotificationDrawer = ({
  isOpen,
  onClose,
  defaultFilterType,
  ...modalContentProps
}: React.PropsWithChildren<NotificationDrawerProps>) => {
  const navigate = useNavigate();
  const user = useUser();
  const { alert } = useAlert();
  const isRepairAdmin = user?.roles?.includes('RepairAdmin');
  const [, setNotifications] = useState<NotificationProp[]>([]);
  const [notificationsCardData, setNotificationsCardData] = useState<
    NotificationCardDataProp[]
  >([]);
  const [page] = useState(1);
  const [pageSize] = useState(100);
  const [showArchived, setShowArchived] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(
    defaultFilterType?.pageSource ?? 'All',
  );
  const [archiveSelectionActive, setArchiveSelectionActive] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState<string[]>(
    [],
  );

  const { data: notificationsData, isPending: notificationsPending } = useQuery(
    {
      queryKey: ['notifications'],
      queryFn: async () =>
        DB.GET('/notifications', {
          params: {
            query: {
              page,
              pageSize,
              types: NotificationTypeMap[defaultFilterType?.pageSource],
              archived: showArchived,
            },
          },
        }).then((res: { data: NotificationsResponse[]; error: any }) => {
          if (res.error) {
            console.error(res.error);
            throw res.error;
          }
          return res.data?.notifications?.filter(
            (notification) => !notification.isRead,
          );
        }),
    },
  );

  const {
    mutateAsync: archiveSelectedNotifications,
    isPending: archiveSelectedNotificationsPending,
  } = useMutation({
    mutationFn: async (data: string[]) =>
      DB.POST('/notifications/archive', {
        body: {
          notificationIds: data,
        },
      }).then(({ response }) => {
        if (response.ok) {
          alert({
            status: 'success',
            message: `Success! ${data?.length} Notification(s) Archived`,
            dismissible: true,
            timeout: 5000,
          });
        } else {
          alert({
            status: 'error',
            message:
              'Something went wrong, could not archive these notifications',
            timeout: 10000,
          });
        }
        onClose();
      }),
  });

  const processNotifications = useCallback(
    (notificationsData) => {
      let messageId: string;
      let messageType: string;

      const processedData = notificationsData
        .filter(
          (notification) =>
            notification.type !== 'CriticalTicketCreation' ||
            (notification.type === 'CriticalTicketCreation' && isRepairAdmin),
        )
        .map((notification: NotificationProp) => {
          const { sentAt, type } = notification;

          const messageKey = NotificationSourceKeys.filter(
            (key) => notification[key] !== null,
          ).map((key) => {
            messageId = notification[key];
            messageType = key;
            let formattedKey = key
              .replace('RequestId', '')
              .replace('EntryId', '')
              .replace('TicketId', '');

            formattedKey = formattedKey
              .replace(/^[a-z]/, (char) => char.toUpperCase())
              .replace(/([a-z])([A-Z])/g, '$1 $2');
            return formattedKey;
          })[0];

          const date = new Date(sentAt);
          const formatter = new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          });

          const isCriticalRepairTicket =
            messageKey === 'Repair' && type === 'CriticalTicketCreation';

          return {
            id: notification.id, // id is the pointer to the whole thread, currently used for Archiving
            subject: isCriticalRepairTicket
              ? `New Critical ${messageKey} Ticket`
              : `${messageKey} Request for job #${notification.jobNumber}`,
            subheader: `${notification.sender} - ${formatter.format(date)}`,
            isRead: notification?.isRead,
            icon: isCriticalRepairTicket ? 'Critical' : messageKey,
            messageId: messageId,
            messageType: messageType,
            sentAt,
          };
        });

      return processedData;
    },
    [isRepairAdmin],
  );

  const handleNotificationNavigation = (
    messageDestination: string,
    ticketId: string,
  ) => {
    const destinationKey = RoutePageMap[messageDestination];
    navigate(`/${destinationKey}/${ticketId}`);
    onClose();
  };

  const handleNotificationFilter = useCallback(
    async (filterType: string) => {
      const typeInput = NotificationTypeMap[filterType];
      try {
        const response = await DB.GET('/notifications', {
          params: {
            query: {
              page,
              pageSize,
              types: typeInput !== 'All' ? typeInput : '',
              archived: showArchived,
            },
          },
        });

        if (response?.data) {
          const notificationData = response.data?.notifications;
          const processedData = processNotifications(notificationData);
          setNotifications(notificationData);
          setNotificationsCardData(processedData);
          setSelectedFilter(filterType);
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [page, pageSize, processNotifications, showArchived],
  );

  const toggleArchiveSelection = useCallback(() => {
    setArchiveSelectionActive((prev) => !prev);
    setSelectedNotifications([]);
  }, []);

  const selectAllNotifications = useCallback(() => {
    setSelectedNotifications((prevSelected) => {
      const allNotificationIds = notificationsCardData.map(
        (notification) => notification.id,
      );
      const uniqueMessageIds = [
        ...new Set([...prevSelected, ...allNotificationIds]),
      ];
      return uniqueMessageIds;
    });
  }, [notificationsCardData]);

  const toggleNotificationSelection = useCallback((notificationId: string) => {
    setSelectedNotifications((prevSelected: string[]) => {
      if (prevSelected.includes(notificationId)) {
        return prevSelected.filter((id) => id !== notificationId);
      } else {
        return [...prevSelected, notificationId];
      }
    });
  }, []);

  const archiveNotifications = async (notificationIds: string[]) => {
    await archiveSelectedNotifications(notificationIds).then(() => {
      setSelectedNotifications([]);
      setArchiveSelectionActive(false);
    });
  };

  useEffect(() => {
    if (!notificationsPending) {
      const processedData = processNotifications(notificationsData);
      setNotifications(notificationsData);
      setNotificationsCardData(processedData);
    }
  }, [notificationsData, notificationsPending, processNotifications]);

  useEffect(() => {
    const setInitialCall = async () => {
      try {
        await handleNotificationFilter(selectedFilter);
      } catch (error) {
        console.error(error);
        throw error;
      }
    };
    if (isOpen) {
      setInitialCall();
    }
  }, [isOpen, handleNotificationFilter, selectedFilter]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      useRNModal
      sx={{
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        justifyContent: 'flex-end',
      }}
    >
      <ModalBackdrop />
      <ModalContent
        borderTopLeftRadius="$md"
        borderBottomLeftRadius="$md"
        borderTopRightRadius={0}
        borderBottomRightRadius={0}
        h="$full"
        w="$100"
        {...modalContentProps}
      >
        <ModalHeader
          w="$full"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box w="$7" alignItems="center">
            {showArchived ? (
              <Button
                w="$4"
                variant="link"
                onPress={() => setShowArchived(false)}
              >
                <ButtonIcon as={Icons.ChevronLeft} color="$black" />
                <ButtonText>Back</ButtonText>
              </Button>
            ) : null}
          </Box>
          <Heading
            w="$full"
            textAlign="center"
            justifyContent="center"
            alignContent="center"
          >
            Notifications
          </Heading>
          <Pressable width="$7" onPress={onClose}>
            <Icon as={Icons.X} size="xl" />
          </Pressable>
        </ModalHeader>
        <VStack
          alignItems="center"
          w="$full"
          flex={1}
          justifyContent="space-between"
        >
          <VStack w="$full" alignItems="center" space="lg">
            <Text
              fontSize={16}
              color="$black"
              textAlign="center"
              w="$full"
              lineHeight={'$xl'}
              mt={'$1'}
              fontWeight="$bold"
              borderBottomWidth={4}
              borderBottomColor="$primary600"
            >
              {showArchived
                ? 'Archived Notifications'
                : 'Messages & Status Updates'}
            </Text>
            <HStack
              id="notifications-buttons-container"
              w="$full"
              alignItems="center"
              backgroundColor="$white"
              justifyContent={
                archiveSelectionActive ? 'space-between' : 'center'
              }
              space="lg"
              borderBottomWidth={1}
              borderBottomColor="#e0e0e0"
              paddingTop={12}
              top={-16}
            >
              {archiveSelectionActive && (
                <Button variant="link" onPress={selectAllNotifications}>
                  <Text color="$primary700" fontWeight="$bold">
                    Select All
                  </Text>
                </Button>
              )}
              {!archiveSelectionActive &&
                ['All', 'Repair', 'Parts', 'Time', 'Truck'].map((filter) => (
                  <FilterPill
                    key={filter}
                    filterType={filter}
                    selected={selectedFilter === filter}
                    onPress={() => handleNotificationFilter(filter)}
                  />
                ))}
              {!showArchived && (
                <IconButton
                  onPress={toggleArchiveSelection}
                  marginBottom="$2"
                  marginRight="$4"
                >
                  <Icon as={Icons.Archive} color="$primary700" size="xl" />
                </IconButton>
              )}
            </HStack>
            {notificationsCardData.length === 0 && (
              <Text
                fontSize={14}
                color="$black"
                fontWeight="$bold"
                textAlign="center"
                w="$full"
                height="$32"
                margin={'auto'}
                justifyContent="center"
                alignContent="center"
              >
                No Notifications
              </Text>
            )}
            <ScrollView
              h="$full"
              w="$full"
              style={{ flex: 1 }}
              showsVerticalScrollIndicator={true}
            >
              <View style={{ height: '80vh' }}>
                {notificationsCardData.length > 0 &&
                  notificationsCardData.map((notification) => {
                    return (
                      <NotificationCard
                        key={notification?.id}
                        id={notification?.id}
                        iconName={iconMap[notification.icon]}
                        subject={notification?.subject}
                        subheader={notification?.subheader}
                        readMessage={notification?.isRead}
                        onPress={async () =>
                          archiveSelectionActive
                            ? null
                            : handleNotificationNavigation(
                                notification?.messageType,
                                notification?.messageId,
                              )
                        }
                        archiveSelectionActive={archiveSelectionActive}
                        isSelected={selectedNotifications.includes(
                          notification?.id,
                        )}
                        onSelectToggle={() =>
                          toggleNotificationSelection(notification?.id)
                        }
                      />
                    );
                  })}
                {!archiveSelectionActive && (
                  <HStack justifyContent="center" alignContent="center" mt="$4">
                    <Button
                      onPress={() => setShowArchived((prev) => !prev)}
                      w={'$3/5'}
                      variant="ghost"
                      backgroundColor="$white"
                      borderColor="$primary700"
                      borderWidth="$1"
                    >
                      <ButtonIcon as={Icons.Archive} color="$primary700" />
                      <ButtonText color="$primary700">
                        {`${showArchived ? 'Close' : 'View'} Archived Notifications`}
                      </ButtonText>
                    </Button>
                  </HStack>
                )}
              </View>
            </ScrollView>
            {archiveSelectionActive && (
              <View
                position="absolute"
                alignItems="center"
                bottom="$1/6"
                left="$8"
                right="$8"
              >
                <Button
                  onPress={() => archiveNotifications(selectedNotifications)}
                  w={'$3/5'}
                  isDisabled={archiveSelectedNotificationsPending}
                >
                  {archiveSelectedNotificationsPending ? (
                    <Spinner />
                  ) : (
                    <>
                      <ButtonIcon as={Icons.Archive} />
                      <ButtonText color="$white">Archive Selection</ButtonText>
                    </>
                  )}
                </Button>
              </View>
            )}
          </VStack>
          <HStack w="$full" mb="$16" />
        </VStack>
      </ModalContent>
    </Modal>
  );
};

const NotificationCard = ({
  id,
  iconName,
  subject,
  subheader,
  readMessage = true,
  onPress,
  archiveSelectionActive = false,
  isSelected = false,
  onSelectToggle,
}: NotificationCardProp) => {
  const iconColor = readMessage ? '#A9A9A9' : '$primary700';
  const textWeight = readMessage ? '200' : 'bold';

  return (
    <TouchableOpacity onPress={onPress} style={styles.cardContainer}>
      <View style={styles.row}>
        {archiveSelectionActive ? (
          <Checkbox
            isChecked={isSelected}
            onChange={onSelectToggle}
            value={id}
            aria-label={subject}
          >
            <CheckboxIndicator mr="$2">
              <CheckboxIcon as={Icons.Check} />
            </CheckboxIndicator>
          </Checkbox>
        ) : (
          <Icon as={iconName} color={iconColor} size="xl" />
        )}
        <View style={styles.textContainer}>
          <Text style={[styles.subject, { fontWeight: textWeight }]}>
            {subject}
          </Text>
          <Text style={[styles.subheader]}>{subheader}</Text>
        </View>
        <ChevronRight stroke={readMessage ? '#A9A9A9' : '#BE0202'} />
      </View>
    </TouchableOpacity>
  );
};

const FilterPill = ({
  filterType,
  onPress,
  selected,
}: {
  filterType: string;
  onPress: () => void;
  selected: boolean;
}) => {
  const pillColor = !selected ? '#FFF' : '#9D0101';
  const pillTextColor = !selected ? '#000' : '#FFF';

  return (
    <TouchableOpacity
      style={[styles.filterPill, { backgroundColor: pillColor }]}
      onPress={onPress}
    >
      <Text style={[styles.pillText, { color: pillTextColor }]}>
        {filterType}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    alignItems: 'normal',
    justifyContent: 'flex-start',
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    maxHeight: 70,
    width: '100%',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    maxHeight: 'auto',
  },
  textContainer: {
    flex: 1,
    marginLeft: 12,
  },
  subject: {
    fontSize: 16,
    color: '#000',
  },
  subheader: {
    fontSize: 12,
    color: '#666',
    fontWeight: '300',
  },
  filterPill: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 30,
    marginRight: 4,
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 5, // Adds shadow on Android
  },
  pillText: {
    fontSize: 14,
    fontWeight: '600',
  },
});
