import React, { useEffect, useState } from 'react';
import {
  VStack,
  Text,
  Radio,
  RadioLabel,
  RadioIndicator,
  RadioIcon,
  CircleIcon,
} from '@tonic/central-specialties-ui-themed';
import { TextFormField } from './Form/FormField/specific';

type QuickSelectType = 'missing-timecards' | 'missing-approvals' | 'all' | null;

type MessageFlowProps = {
  quickSelectType: QuickSelectType;
  messageText: string;
  messageOption: string;
  setMessageText: (text: string) => void;
  setMessageOption: (text: string) => void;
};

export const MessageFlow: React.FC<MessageFlowProps> = ({
  quickSelectType,
  messageText,
  messageOption,
  setMessageOption,
  setMessageText,
}) => {
  const [defaultPlaceholder, setDefaultPlaceholder] = useState<string | null>();
  // Determine if default options should be shown
  const isDefaultOptionAvailable =
    quickSelectType === 'missing-timecards' ||
    quickSelectType === 'missing-approvals';

  const handleMessageOptionChange = (option: string) => {
    setMessageOption(option);
    if (option === 'default') {
      if (quickSelectType === 'missing-timecards') {
        setMessageText(
          'You have not completed your prior day’s timecard. Please enter your time in the next hour and submit it to your supervisor for approval.',
        );
        setDefaultPlaceholder(
          'You have not completed your prior day’s timecard. Please enter your time in the next hour and submit it to your supervisor for approval.',
        );
      } else if (quickSelectType === 'missing-approvals') {
        setMessageText(
          'Hello. You have outstanding time entry approvals to complete.',
        );
        setDefaultPlaceholder(
          'Hello. You have outstanding time entry approvals to complete.',
        );
      }
    }
  };

  useEffect(() => {
    if (messageOption === 'default') {
      let defaultMessage = '';
      if (quickSelectType === 'missing-timecards') {
        defaultMessage =
          'You have not completed your prior day’s timecard. Please enter your time in the next hour and submit it to your supervisor for approval.';
        setMessageText(defaultMessage);
        setDefaultPlaceholder(defaultMessage);
      } else if (quickSelectType === 'missing-approvals') {
        defaultMessage =
          'Hello. You have outstanding time entry approvals to complete.';
        setMessageText(defaultMessage);
        setDefaultPlaceholder(defaultMessage);
      }
    }
  }, [messageOption, quickSelectType, setMessageText]);

  return (
    <VStack>
      <Text mt="$2" textAlign="center">
        {isDefaultOptionAvailable
          ? 'Select a message option below:'
          : 'Send a custom message'}
      </Text>

      {isDefaultOptionAvailable && (
        <Radio.Group
          name="messageOptions"
          value={messageOption}
          onChange={(val) => handleMessageOptionChange(val as string)}
        >
          <Radio value="default" my="$1">
            <RadioIndicator mr="$2">
              <RadioIcon>
                <CircleIcon />
              </RadioIcon>
            </RadioIndicator>
            <RadioLabel>
              {quickSelectType === 'missing-timecards'
                ? 'Send the default Missing Timecard reminder.'
                : 'Send the default Missing Approvals reminder.'}
            </RadioLabel>
          </Radio>
          <Radio value="custom" my="$1">
            <RadioIndicator mr="$2">
              <RadioIcon>
                <CircleIcon />
              </RadioIcon>
            </RadioIndicator>
            <RadioLabel>Send a custom text message.</RadioLabel>
          </Radio>
        </Radio.Group>
      )}

      {isDefaultOptionAvailable && messageOption === 'default' && (
        <Text fontSize="$sm" color="$textLight400" textAlign="center" mt="$2">
          You may edit the default message before sending.
        </Text>
      )}

      <TextFormField
        value={messageText}
        onChange={setMessageText}
        multiline
        placeholder={
          isDefaultOptionAvailable
            ? defaultPlaceholder ?? ''
            : 'Type your custom message...'
        }
      />
    </VStack>
  );
};
